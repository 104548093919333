import { getCookie, isAuth, signout } from "@/actions/auth"
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { SLUG_HEADER } from "@/constants/list-of-routes"
import { useToggle } from "@/hooks/useToggle"
import { logOutAPP } from "@/redux/actions/main"
import { setChatIsVisible, setIncomingUContactPhoneNumber, setOpenUContact, setUContactError, setUContactPosition } from "@/redux/slices/headers"
import _ from "lodash"
import { Bell, BookMarked, Headphones, History, LogOut, Phone, UserSquare2 } from "lucide-react"
import "moment/locale/es"
import { useRouter } from "next/router"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../crm/index.css"
import useEventObserver from "../settings/phone-providers/ucontact/hooks/useEventObserver"
import { Avatar } from "../ui/avatar"
import ViewActivityModal from "./Activity/ViewActivityModal"

export default function UserProfile({
  user,
  activeNotification = false,
  userName,
  slug,
  companyName,
  isCollapsedMenu,
}) {
  const router = useRouter()
  const dispatch = useDispatch()
  const chatVisible = useSelector((state: any) => state.header.chatIsVisible)
  const isOpenUContact = useSelector((state: any) => state.header.isOpenUContact)
  const userPhoneProviderConfig = useMemo(() => _.get(isAuth(), 'phoneProviders[0].config', {}), [isAuth()?._id])
  useEventObserver(userPhoneProviderConfig);

  const [openActivityModal, toggleActivityModal] = useToggle<boolean>()

  const handleOpenUContact = () => {
    if (isOpenUContact) {
      dispatch(setUContactPosition({ x: -5000, y: -5000 }));
      dispatch(setUContactError(''))
      dispatch(setIncomingUContactPhoneNumber(''))
    }
    dispatch(setOpenUContact(!isOpenUContact))
  }

  const isGhostAdmin = useMemo(() => getCookie('admin_token'), [])

  const handleChangeChatVisibility = () => {
    dispatch(setChatIsVisible(!chatVisible))
  }

  const hasUContact = useMemo(() => {
    return user?.phoneProviders?.find((provider) => provider.provider === 'ucontact')
  }, [user])

  return (
    <>
      <Popover>
        <PopoverTrigger className="overflow-hidden" asChild>
          <button
            className={`w-full max-w-full ${slug === SLUG_HEADER.SETTINGS ? 'bg-blue-100' : ''} truncate outline-none ${isCollapsedMenu ? "lg:justify-center h-[38px] w-[42px] m-auto" : ""
              } relative flex items-center space-x-2 hover:bg-blue-100 truncate  rounded-[4px] p-1 shrink-0`}
          >
            <div className="relative">
              <Avatar
                className={`bg-slate-200 bg-primary duration-100 text-white w-8 h-8 ${isCollapsedMenu ? "lg:w-7 lg:h-7" : ""
                  } flex items-center justify-center shrink-0 text-[10px] font-semibold`}
              >
                {user &&
                  (user.photo ? (
                    <img
                      src={user.photo}
                      className="w-full h-full object-cover"
                      alt="User profile"
                    />
                  ) : (
                    `${user?.name?.[0].toUpperCase() || ''}${user?.lastname?.[0]?.toUpperCase() || ''}`
                  ))}
              </Avatar>
              {activeNotification && (
                <span className="w-[9px] right-[1px] bottom-[1px] h-[9px] absolute rounded-full bg-red-500"></span>
              )}
            </div>
            <div
              className={`${isCollapsedMenu ? "lg:scale-0 lg:hidden" : ""
                } duration-500 flex flex-col truncate items-start justify-center`}
            >
              <p className="text-[14px] font-medium" title={userName?.length > 20 ? userName : ''}>{userName}</p>
              <p className="text-slate-500 max-w-full truncate text-[11px]  text-left mt-[-2px]" title={companyName?.length > 20 ? companyName : ''}>
                {companyName ?? ""}
              </p>
            </div>
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-44 p-0">
          <Command>
            <CommandList>
              <CommandGroup>
                <CommandItem
                  className="cursor-pointer relative"
                  onSelect={() => router.push("/settings")}
                >
                  <UserSquare2 className="mr-2 h-4 w-4" />
                  <span>Mi perfil</span>
                </CommandItem>
                <CommandItem
                  className="cursor-pointer relative"
                  onSelect={toggleActivityModal}
                >
                  <History className="mr-2 h-4 w-4" />
                  <span>Actividad</span>
                </CommandItem>
                <CommandItem
                  className="cursor-pointer relative"
                  onSelect={() => router.push("/settings/learn")}
                >
                  <BookMarked className="mr-2 h-4 w-4" />
                  <span>Aprender</span>
                </CommandItem>
                {hasUContact && (
                  <CommandItem
                    className={`cursor-pointer ${isOpenUContact ? 'text-red-600' : ''}`}
                    onSelect={handleOpenUContact}
                  >
                    <Phone className="mr-2 h-4 w-4" />
                    <span>{!isOpenUContact ? 'Abrir' : 'Cerrar'} uContact</span>
                  </CommandItem>
                )}
                <CommandItem
                  className="cursor-pointer"
                  onSelect={() => router.push("/all-notifications")}
                >
                  <span className="relative">
                    {activeNotification && (
                      <span className="w-2 right-[10px] -top-[2px] h-2 absolute rounded-full bg-red-500"></span>
                    )}
                    <Bell className="mr-2 h-4 w-4" />
                  </span>
                  <span>Notificaciones</span>
                </CommandItem>
                {isGhostAdmin && (
                  <CommandItem
                    className="cursor-pointer"
                    onSelect={() => handleChangeChatVisibility()}
                  >
                    <Headphones className="mr-2 h-4 w-4" />
                    <span>{chatVisible ? 'Ocultar soporte' : 'Ver soporte'}</span>
                  </CommandItem>
                )}
                <CommandItem
                  className="cursor-pointer"
                  onSelect={() => {
                    logOutAPP(null)
                    signout(() => window.location.replace("/login"))
                  }}
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Cerrar sesión</span>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      <ViewActivityModal
        onOpenChange={toggleActivityModal}
        open={openActivityModal}
      />
    </>
  )
}