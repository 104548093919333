
import { getCompany } from '@/actions/auth';
import _ from 'lodash';
import { useMemo } from 'react';

const archivingReasons = _.get(getCompany(), 'archivingReasons', []);
const archivingReasonsToMap = _.keyBy(archivingReasons, 'code');

export const getArchivingReason = (code: string) => archivingReasonsToMap[code];

export default function useGetArchivingReason({ code }: { code?: string }) {

    const [reasons, allReasons] = useMemo(() => {
        let reasons = archivingReasons.filter(reason => reason.active)
        return [reasons, archivingReasons]
    }, [])

    if (!code || code == '') return { reasons, reason: {} }

    const find = allReasons.find(tag => tag.code === code) || {
        name: code,
        code: code
    }

    return { reasons, reason: find }
}
