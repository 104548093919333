import { getActivity } from "@/actions/user";
import { Activity } from "@/interfaces/Interface";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

type Params = {
    gtDate: string,
    ltDate: string,
    limit: number,
    page: number
}

export default function useGetActivity({ enabled = true }: { enabled?: boolean } = {}) {
    const [activity, setActivity] = useState<Activity[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const [params, setParams] = useState<Params>({
        gtDate: dayjs().startOf('day').toISOString(),
        ltDate: dayjs().toISOString(),
        limit: 25,
        page: 1
    });

    const hasMore = activity.length < totalCount;

    const { data, isLoading } = useQuery({
        queryKey: ['user-activity', params],
        queryFn: () => getActivity(params),
        enabled
    });

    useEffect(() => {
        if (!data?.activity) return;
        setTotalCount(data.paging.totalCount);
        setActivity(lastValue => params.page == 1 ? data.activity : [...lastValue, ...data.activity])
    }, [data])

    const onChangeParams = (params: Partial<Params>) => {
        setParams(lastValue => ({ ...lastValue, ...params, page: 1 }))
    }

    const onNextPage = () => {
        setParams(lastValue => ({ ...lastValue, page: lastValue.page + 1 }))
    }

    return {
        onChangeParams,
        onNextPage,
        isLoading,
        hasMore,
        activity
    }
}
