import { isAuth } from '@/actions/auth'
import CustomTabs from '@/components/tabs/CustomTabs'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { ModalPropsBase } from '@/interfaces/Interface'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { z } from 'zod'
import { createInteraction } from '../../Prospect/libs/helpers/interaction'
import Sections from './sections'

dayjs.extend(utc);
dayjs.extend(timezone);

export const MeetingTabs = {
  Now: 'now',
  Schedule: 'schedule'
} as const

const MeetingSchema = z.object({
  meetingType: z.string({
    required_error: "Tipo de reunión requerido"
  }),
  comment: z.string().max(2500).optional(),
  whenMeeting: z.string().optional(),
  summary: z.string({
    required_error: 'El asunto de la reunión es requerido'
  }),
  schedule: z.date({ required_error: "Fecha requerida" }),
  reminders: z.array(z.object({
    unitAndNumber: z.object({
      number: z.number(),
      unit: z.string()
    }).optional(),
    messageContent: z.object({
      message: z.string()
    }).optional()
  })).optional()
})

export type MeetingSchemaType = z.infer<typeof MeetingSchema>
export type ReturnFormMeeting = UseFormReturn<MeetingSchemaType>
export type MeetingTabsType = typeof MeetingTabs[keyof typeof MeetingTabs]

interface AddMeetingModalProps extends ModalPropsBase {
  prospectId: string
}

export interface SectionPropsBase {
  contextForm: ReturnFormMeeting
}

const defaultValues = {
  meetingType: "",
  whenMeeting: "",
  schedule: undefined,
  comment: "",
  reminders: []
}

export default function AddMeetingModal({ onOpenChange, open, prospectId }: AddMeetingModalProps) {
  const [tabSelected, setTabSelected] = useState<MeetingTabsType>(MeetingTabs.Now)

  const contextForm = useForm<MeetingSchemaType>({
    resolver: zodResolver(MeetingSchema),
    defaultValues
  })

  const userTimezone: string = dayjs?.tz?.guess();
  const dispatch = useDispatch()

  const values = contextForm.watch();
  const isValidForm = contextForm.formState.isValid && values.meetingType;

  useEffect(() => {
    let meetingSettings: any = _.get(isAuth(), 'meetingSettings', false)

    if (!meetingSettings || (Object.keys(meetingSettings).length === 0) || meetingSettings?.reminders?.length === 0) {
      meetingSettings = {
        reminders: [{
          unitAndNumber: {
            number: 30,
            unit: 'minutes'
          },
          messageContent: {
            message: ''
          }
        }]
      }
    }

    contextForm.reset({ ...defaultValues, ...meetingSettings })
  }, [open])

  const onChangeTab = (tab: MeetingTabsType) => {
    contextForm.setValue('whenMeeting', tab)
    setTabSelected(tab)
  }

  const onHandleSubmit = async (values: MeetingSchemaType) => {
    values.whenMeeting = tabSelected

    if (tabSelected == MeetingTabs.Schedule && values.schedule < new Date()) return contextForm.setError('schedule', {
      message: "La fecha seleccionada tiene que ser superior a la actual"
    })

    if (tabSelected === MeetingTabs.Now) {
      values = {
        ...values,
        schedule: new Date(),
        reminders: [],
        whenMeeting: tabSelected
      }
    }

    onOpenChange(false)

    await createInteraction({
      dispatch,
      type: 'meeting',
      data: {
        id: prospectId,
        values: {
          ...values,
          timezone: userTimezone
        }
      }
    })
  }

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent className={`p-0 gap-0 flex flex-col max-w-[800px] ${tabSelected == MeetingTabs.Now ? 'h-auto' : 'h-[90vh]'} `}>
        <DialogHeader className='px-5 pt-5'>
          <DialogTitle>
            Crear reunión
          </DialogTitle>
          <DialogDescription>
            Aquí podrá crear su reunión ahora o agendarla para luego.
          </DialogDescription>
        </DialogHeader>
        <div className="h-[40px] shrink-0 mt-2 border-b border-gray-200">
          <CustomTabs
            optionsList={[['Ahora', MeetingTabs.Now], ['Agendar', MeetingTabs.Schedule]]}
            onChangeValue={(value: MeetingTabsType) => onChangeTab(value)}
            classNameTab='flex-1 w-full'
            classNameButton='w-full items-center text-[17px] flex justify-center'
            value={tabSelected}
          />
        </div>
        <div className=' relative flex-grow '>
          <div className={`${tabSelected == MeetingTabs.Now ? '' : 'absolute top-0 overflow-y-auto left-0 w-full h-full'} py-3 px-5`}>
            <Form {...contextForm}>
              <form
                id='formSendMeeting'
                className='flex flex-col py-3 gap-4'
                onSubmit={contextForm.handleSubmit(onHandleSubmit)}
              >
                <Sections
                  contextForm={contextForm}
                  tabSelected={tabSelected}
                />
              </form>
            </Form>
          </div>
        </div>
        <DialogFooter className='flex px-5 bg-gray-100 border-t-gray-200 border-t py-4'>
          <div className='w-full flex items-center gap-2 justify-end'>
            <Button
              variant={'outline-primary'}
              size={'xs'}
              onClick={() => onOpenChange(false)}
            >
              Cancelar
            </Button>
            <Button
              variant={'california'}
              size={'xs'}
              onClick={() => onHandleSubmit(contextForm.getValues())}
              disabled={
                (tabSelected === MeetingTabs.Now)
                  ? !contextForm.getValues('meetingType')
                  : (!isValidForm || !contextForm.getValues('summary').trim())
              }
            >
              {
                (tabSelected === MeetingTabs.Now)
                  ? 'Crear ahora'
                  : 'Agendar'
              }
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog >
  )
}