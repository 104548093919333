import { getCookie, isAuth } from "@/actions/auth";
import { useChannels } from "@/components/channels/helpers/hooks/useChannels";
import MeetingModal from "@/components/MeetingModal";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { API_NEXT } from "@/httpConfig";
import { PhoneCall } from "@/interfaces/crm/contact";
import { setOpenReminderCall } from "@/redux/slices/crm";
import { Facebook, Instagram } from "@mui/icons-material";
import { CardActions, Paper } from "@mui/material";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useMemo } from "react";
import ChatInput, { ButtonActions } from "../ChatInput";
import FancyTemplateSelect from "../ChatInput/TemplateSelect/new";
import AddMeetingModal from "../Modal/meeting/AddMeetingModal";
import useGetIsBlockNumber from "../Prospect/libs/hooks/useGetBlockNumber";
import RequestSale from "../requestsale/RequestSale";
import GracePeriodAlert from "./GracePeriodAlert";
import HumanAgentTagButtonAlert from "./HumanAgentTagButtonAlert";
import PhoneResponse, { callState } from "./PhoneResponse";
import ReminderFast from "./ReminderFast";

export function ChatInputHandler({ action, prospect, sendMessage, sendMultimedia, sendMultimediaPersonalWPP, id, downChatFc, dispatch, handelActionPress, activeChannel, setAction, fromBackOffice }) {
    const { handlePressBlockNumber, isLockedFeature } = useGetIsBlockNumber()
    const { gracePeriodOpen, templates = [], isLoading: isGettingTemplates, tag } = useChannels(activeChannel, prospect)

    const isLockedChat = useMemo(() => isLockedFeature('disableChat', prospect.phones[0]), [prospect])

    const getResponseCall = (interaction: PhoneCall) => {
        if (!interaction || interaction.type != "phoneCall" || (interaction.type === 'phoneCall' && interaction.localStatus && interaction.localStatus !== 'success')) return false
        return interaction.content.state == "calling"
    }

    const handleOnCall = (callResult: callState) => {
        if (callResult === "cancel") dispatch(setOpenReminderCall(prospect._id))
        else if (callResult === 'done') handelActionPress("note")
        downChatFc();
    }

    const isEnabledNewMeetingInterface = useFeatureFlagEnabled('nueva-interfaz-de-reuniones');

    const handlePressLockedChat = () => isLockedChat && handlePressBlockNumber(isLockedChat)

    const handleViewConversation = () => {
        if (!activeChannel) return;

        const token = getCookie('token');

        const searchParams = new URLSearchParams();

        searchParams.append('socialNetworkId', activeChannel?.config?.pageId)
        searchParams.append('socialNetworkContactId', activeChannel?.config?.PSID)
        searchParams.append('channelType', activeChannel.type)
        searchParams.append('accessToken', token)
        searchParams.append('username', activeChannel?.config?.username)

        const url = `${API_NEXT}/crm/contact/${prospect._id}/socialNetwork?${searchParams.toString()}`;
        window.open(url, "_blank");
    }

    const lastInteraction = useMemo(() => {
        return prospect.interactions.find(({ type }) => type !== 'contactTaken')
    }, [prospect.interactions])

    return (
        <>
            {
                isEnabledNewMeetingInterface
                    ? <MeetingModal
                        open={action == "meeting"}
                        onOpenChange={() => dispatch(setAction("note"))}
                        client={{
                            id: prospect._id,
                            photo: prospect.photo,
                            lastName: prospect.lastName,
                            firstName: prospect.firstName,
                            email: (prospect?.emails || []).find(email => email)
                        }}
                    />
                    : <AddMeetingModal
                        onOpenChange={() => dispatch(setAction("note"))}
                        prospectId={prospect._id}
                        open={action == "meeting"}
                    />
            }
            <div className="w-full pb-0 h-full">
                {
                    getResponseCall(lastInteraction)
                        ? (
                            <PhoneResponse
                                id={prospect._id}
                                interactionid={lastInteraction._id}
                                phone={lastInteraction.content.phone}
                                onCall={handleOnCall}
                                fromBackOffice={fromBackOffice}
                            />
                        )
                        : action == "form" ? (
                            <RequestSale
                                open={action == "form"}
                                contactid={id}
                                close={() => dispatch(setAction("select-more"))}
                            />
                        )
                            : [
                                "whatsapp",
                                "facebook",
                                "instagram",
                                "channel-chat",
                                "channel-sms"
                            ].includes(action) ? (
                                gracePeriodOpen
                                    ? (
                                        <ChatInput
                                            send={(__, message) => sendMessage(undefined, message, { tag })}
                                            disableChat={!!isLockedChat || !gracePeriodOpen}
                                            onClickDisabledChat={handlePressLockedChat}
                                            goBack={() => dispatch(setAction("note"))}
                                            placeholder={"Escribe un mensaje..."}
                                            sendMultimedia={sendMultimedia}
                                            channel={
                                                action == 'facebook' ? { name: "Facebook", type: 'fbk' }
                                                    : action == 'instagram' ? { name: "Instagram", type: 'ig' }
                                                        : activeChannel
                                            }
                                            extraActions={['ig', 'fb'].includes(activeChannel?.type)
                                                ? [
                                                    {
                                                        render: () => {
                                                            return <SimpleTooltip
                                                                content={`Conversación en ${activeChannel.type == 'ig' ? 'Instagram' : 'Facebook'}`}
                                                            >
                                                                <ButtonActions
                                                                    onClick={handleViewConversation}
                                                                    className="z-40 relative"
                                                                >
                                                                    {
                                                                        (activeChannel.type == 'ig')
                                                                            ? <Instagram style={{ width: '21px', height: '21px' }} />
                                                                            : <Facebook style={{ width: '21px', height: '21px' }} />
                                                                    }
                                                                </ButtonActions>
                                                            </SimpleTooltip>
                                                        }
                                                    },
                                                    {
                                                        active: !!tag,
                                                        render: () => <HumanAgentTagButtonAlert />
                                                    }
                                                ] : []}
                                            options={{ enabledScheduledMessage: activeChannel?.type == 'unofficial_wpp' }}
                                            multimedia={
                                                ['unofficial_wpp', 'fb', 'ig', 'whatsapp_business', 'whatsapp_360_dialog'].includes(activeChannel?.type) ? {
                                                    voice: true,
                                                    image: true,
                                                    file: true
                                                } : {}
                                            }
                                        />
                                    ) : ['ig', 'fb'].includes(activeChannel?.type)
                                        ? <GracePeriodAlert
                                            handleRedirect={handleViewConversation}
                                            medium={activeChannel.type}
                                        />
                                        : (
                                            <FancyTemplateSelect
                                                onBackChange={() => gracePeriodOpen ? handelActionPress('note') : dispatch(setAction(action))}
                                                type={!gracePeriodOpen ? 'send-fast-message' : 'user-template'}
                                                forceChannelTemplates={templates || []}
                                                loading={isGettingTemplates}
                                                channel={activeChannel}
                                                sendMessage={(message) => {
                                                    if (message.type == 'text') {
                                                        sendMessage(undefined, message.text, message.template);
                                                    }
                                                }}
                                            />
                                        )
                            )
                                : (action == "unofficial-whatsapp" || action === 'unofficial-whatsapp-from-shared-inbox')
                                    ? (
                                        <ChatInput
                                            send={sendMessage}
                                            disableChat={!!isLockedChat}
                                            onClickDisabledChat={handlePressLockedChat}
                                            sendMultimedia={sendMultimediaPersonalWPP}
                                            placeholder="Escribe un mensaje..."
                                            goBack={() => dispatch(setAction("select"))}
                                            channel={{ name: 'Whatsapp personal', type: 'unofficial_wpp' }}
                                            options={{ enabledScheduledMessage: true }}
                                            multimedia={
                                                {
                                                    voice: true,
                                                    image: true,
                                                    file: true
                                                }
                                            }
                                        />
                                    )
                                    : action == "whatsapp-note" ? (
                                        <ChatInput
                                            disableChat={!!isLockedChat}
                                            onClickDisabledChat={handlePressLockedChat}
                                            send={sendMessage}
                                            goBack={() => dispatch(setAction("select"))}
                                            placeholder={"Describe conversación por Whatsapp"}
                                        />
                                    ) : action == 'update-whatsapp-message'
                                        ? <ChatInput
                                            send={sendMessage}
                                            disableChat={!!isLockedChat}
                                            placeholder="Escribe un mensaje..."
                                            goBack={() => dispatch(setAction("select"))}
                                            options={{ enabledScheduledMessage: false }}
                                        />
                                        : action == "reminder_long" ? (
                                            <Paper sx={{ borderRadius: "0px" }}>
                                                <CardActions disableSpacing sx={{ mt: "auto", borderRadius: "0px" }}>
                                                    <ReminderFast
                                                        id={id}
                                                        type={"long"}
                                                        onClose={() => {
                                                            dispatch(setAction("note"));
                                                            downChatFc();
                                                        }}
                                                    />
                                                </CardActions>
                                            </Paper>
                                        )
                                            : (action == "meeting")
                                                ? null
                                                : (
                                                    <ChatInput
                                                        send={sendMessage}
                                                        goBack={() => dispatch(setAction("select"))}
                                                        placeholder={"Escribe una nota..."}
                                                        colorSchemeConfig={{
                                                            background: "#FEF3C7",
                                                            borders: "#FAAC14",
                                                            bordersFocus: "#FAAC14",
                                                            submitButton: "#FAAC14",
                                                            backButton: "#FAAC14",
                                                            emojiButton: "GrayText",
                                                        }}
                                                        multimedia={{
                                                            image: isAuth()?.roles.includes('admin'),
                                                            file: isAuth()?.roles.includes('admin')
                                                        }}
                                                    />
                                                )
                }
            </div>
        </>
    )
}

