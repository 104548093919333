import CarouselDate from "@/components/ui/carousel-date";
import EmptyMessage from "@/components/ui/complex/empty-action";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import InfiniteScroll from "@/components/ui/infinite-scroll";
import useGetActivity from "@/hooks/user/useGetActivity";
import { ModalPropsBase } from "@/interfaces/Interface";
import { Routes } from "@/routes";
import dayjs from "dayjs";
import { History } from "lucide-react";
import { useRouter } from "next/router";
import { useState } from "react";
import ActivityInteractionCard from "./ActivityInteractionCard";
import ActivityLoader from "./ActivityLoader";

interface ViewActivityModalProps extends ModalPropsBase { }

export default function ViewActivityModal({
    onOpenChange,
    open
}: ViewActivityModalProps) {
    const [date, setDate] = useState<Date>(new Date());
    const { activity, onNextPage, hasMore, isLoading, onChangeParams } = useGetActivity({ enabled: !!open });

    const router = useRouter();

    const redirectToCalendar = () => {
        router.push({
            pathname: Routes.CALENDAR,
            query: new URLSearchParams({ defaultDate: date.toISOString() }).toString()
        });
    }

    const onHandleChangeDate = (date: Date) => {
        setDate(date);
        onChangeParams({
            gtDate: dayjs(date).startOf('day').toString(),
            ltDate: dayjs(date).endOf('day').toString()
        })
    }

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[550px] gap-0 px-3">
                <DialogHeader className="px-2">
                    <DialogTitle>Actividades</DialogTitle>
                </DialogHeader>
                <div className="px-2 py-4 pb-0 w-full">
                    <CarouselDate
                        classNameButtonValue="w-9 h-9 shrink-0 flex items-center justify-center hover:bg-gray-100 rounded-full"
                        onHandleChangeDate={onHandleChangeDate}
                        onClickDateValue={redirectToCalendar}
                        classNameContainer="mb-3"
                        maxDate={new Date()}
                        date={date}
                    />
                    {
                        !isLoading && activity.length == 0
                            ? <EmptyMessage
                                icon={<History size={32} className="text-gray-500" />}
                                classNameCard="rounded-md min-h-[250px] flex items-center justify-center"
                                description="No se han registrado actividades."
                                message="No hay actividad reciente"
                            />
                            : <InfiniteScroll
                                className="max-h-[400px] overflow-y-auto flex flex-col space-y-2 w-full"
                                dataLength={activity.length}
                                loader={<ActivityLoader />}
                                loading={isLoading}
                                hasMore={hasMore}
                                next={onNextPage}
                            >
                                {
                                    activity.map((act, index) => {
                                        switch (act.type) {
                                            case "interaction": return <ActivityInteractionCard
                                                onClickInteraction={() => onOpenChange(false)}
                                                interaction={act.payload}
                                                key={index}
                                            />
                                            default: return null
                                        }
                                    })
                                }
                            </InfiniteScroll>
                    }
                </div>
            </DialogContent>
        </Dialog>
    )
}