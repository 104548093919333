import { isAuth } from "@/actions/auth"
import { cancelSurvey } from "@/actions/company/nps"
import useDebounce from "@/components/core/functions/useDebouce"
import { useToggle } from "@/hooks/useToggle"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import { handleSetLocalStatus } from "@/redux/slices/crm"
import { useMutation } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import ReplyMessageCard from '../ChatInput/ReplyMessageCard'
import { createInteraction } from "../Prospect/libs/helpers/interaction"
import { ICreateInteraction } from "../Prospect/libs/interfaces"
import { Whatsapp } from "./Icons"
import InteractiveMessageCard, { InteractonStatusType } from "./InteractiveMessageCard"
import WhatsAppActions from "./WhatsAppActions"
import InteractionChannelContent from "./interaction-channel-content"
import MessageChannel from "./message-channel"

const API_WPP = process.env.API_WPP ?? "https://api.getventia.com"

const UnOfficialWhatsappBubble = ({
  interaction,
  profile,
  date,
}) => {
  const [openActions, toggleActions] = useToggle<boolean>();
  const [isHovered, setIsHovered] = useState(false);
  const { localStatus, _id: interactionId, content, contact, agent, suggesstions = [] } = interaction;
  
  const {
    status,
    mediaType,
    deleted,
    origin,
    channelColor,
    npsSurvey,
    channelName,
    message: text,
    media,
    mediaURL,
    replyMessage,
    mimetype,
    isEdited,
    fileName,
    listened,
    externalAdReply,
    sticker
  } = content

  const isMedia = !!mediaType

  const dispatch = useDispatch();

  const mediaUrl = useMemo(() => {
    if (media && media.includes("https://")) return media
    if (media) return API_WPP + "/crm/wppimg/" + media
  }, [media])

  const handleRetry = () => {
    dispatch(handleSetLocalStatus({
      localId: (interactionId as any),
      status: LocalStatus.LOADING,
    }));

    const objRetry: ICreateInteraction = {
      dispatch,
      type: 'unofficial-whatsapp',
      isRetry: true,
      data: {
        isFromSharedInbox: agent?._id !== isAuth()?._id,
        localId: interactionId,
        message: text ?? "",
        contactId: contact,
        type: mediaType,
        file: mediaURL,
        mimetype,
        agent,
        fileName,
        extras: {
          text: text ?? "",
        }
      },
    }

    createInteraction(objRetry);
  };

  return (
    <InteractiveMessageCard
      className={`${(mediaType == 'sticker' && !replyMessage?.id) ? 'bg-transparent shadow-none' : ''}`}
      messageError={'Se ha producido un error en el envío del mensaje.'}
      status={(localStatus as InteractonStatusType) || 'idle'}
      retryFn={() => handleRetry()}
      mediumIcon={<Whatsapp />}
      profile={profile}
      origin={origin}
      agent={agent}
      contactId={interaction.contact}
      interactionId={interaction._id || ''}
      suggestions={suggesstions}
      extraContent={
        <div className={`my-auto flex  ${origin == 'contact' ? 'ml-1' : 'order-[-1] mr-5 '} w-[30px] relative h-full ${(isHovered || openActions) ? 'opacity-100' : 'md:opacity-0'} duration-100 hover:opacity-100`}>
          <WhatsAppActions
            isFromContact={origin == 'contact'}
            contactId={interaction.contact}
            onChangeOpen={toggleActions}
            interaction={interaction}
            id={interaction._id}
            open={openActions}
          />
        </div>
      }
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {(replyMessage?.id || replyMessage?.localId) && (
          <div className="px-2 pt-2">
            <ReplyMessageCard interaction={replyMessage} isReplied={true} />
          </div>
        )}
        <InteractionChannelContent
          mediaType={mediaType}
          description={text}
          listened={listened}
          media={mediaUrl}
          sticker={sticker}
          externalAdReply={externalAdReply}
          isReply={replyMessage}
          fileName={fileName || 'file'}
          origin={origin}
          status={status}
          date={date}
          channel={{
            name: channelName,
            color: channelColor
          }}
        >
          {(mediaType === 'image' || mediaType === 'video') && !text ? null : (
            <>
              <MessageChannel
                origin={origin}
                edited={isEdited}
                className={`${isMedia ? 'p-0 pt-2' : 'p-3'}`}
                showReplyMessage={isHovered}
                replyMessage={replyMessage}
                interaction={interaction}
                mediaType={mediaType}
                listened={listened}
                deleted={deleted}
                channel={{
                  name: channelName,
                  color: channelColor
                }}
                status={status}
                message={text}
                date={date}
              />
              <NpsSurveyItem npsSurvey={npsSurvey} />
            </>
          )}
        </InteractionChannelContent>
      </div>
    </InteractiveMessageCard>
  )
};


interface NpsSurveyItemProps {
  npsSurvey: {
    _id: string,
    surveyStatus: string,
    isCanceled: boolean
  }
}

export function NpsSurveyItem({ npsSurvey }: NpsSurveyItemProps) {
  const [localStatus, setLocalStatus] = useState<boolean>(npsSurvey?.isCanceled);
  const [lastStatus, setLastStatus] = useState(npsSurvey?.isCanceled);
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const { surveyStatus, _id: surveyId } = npsSurvey || {};

  const statusDebounce = useDebounce(localStatus, 500);
  const isValidForChangeStatus = ['pendingDispatch', 'sended'].includes(surveyStatus);

  const { isPending, mutate } = useMutation({
    mutationFn: (value: boolean) => cancelSurvey(surveyId, value),
    onError: () => {
      setLocalStatus(lastStatus);
    }
  });

  const changeStatusButtonText = localStatus
    ? 'Activar encuesta'
    : 'Cancelar encuesta';

  const toggleSurveyStatus = () => {
    setIsChanged(true);
    if (!isPending) setLastStatus(localStatus);
    setLocalStatus(lastValue => !lastValue);
  };

  useEffect(() => {
    if (isValidForChangeStatus && isChanged) {
      mutate(statusDebounce as boolean);
    };
  }, [statusDebounce])

  if (!npsSurvey) return null;
  if (!isValidForChangeStatus) return null;

  return <div className="w-full flex items-center bg-gray-50 p-2 px-4 justify-end">
    {
      isValidForChangeStatus && (
        <button className={`px-2 ${localStatus ? 'text-primary' : 'text-red-500'} font-medium text-[14px] h-5`} onClick={toggleSurveyStatus}>
          {changeStatusButtonText}
        </button>
      )
    }
  </div>
}

export default UnOfficialWhatsappBubble
