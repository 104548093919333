import { ContactAvatar } from "@/components/crm/Prospect";
import {
    Card,
    CardHeader,
    CardTitle
} from "@/components/ui/card";
import { AppearanceConfig, getAppearance } from "@/helpers/featureFlagHelpers";
import { InteractionType } from '@/interfaces/Interface';
import { setRedirectToInteraction } from "@/redux/slices/crm";
import { Routes } from "@/routes";
import { formatDistanceToNow } from "date-fns";
import { es } from 'date-fns/locale';
import dayjs from "dayjs";
import { motion } from 'framer-motion';
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { GetContentByInteractionType, GetTitleByInteractionType } from "./ActivityContents";

interface ActivityInteractionCardProps {
    interaction: InteractionType,
    onClickInteraction?: () => void
}

export default function ActivityInteractionCard({ interaction, onClickInteraction }: ActivityInteractionCardProps) {
    const disableContactPhoto = useMemo(() => getAppearance<boolean>(AppearanceConfig.DISABLE_CONTACT_PHOTO, false), []);

    const dispatch = useDispatch();
    const router = useRouter();

    const GetContactElement = () => {
        const { lastName, firstName, photo, statusTag } = interaction?.contact || {}
        const fullName = [firstName, lastName].filter(Boolean).join(' ');

        return <div
            title={fullName}
            className="flex items-center flex-1 max-w-full overflow-hidden space-x-2"
        >
            <ContactAvatar
                className="w-[27px] h-[27px] border text-[13px]"
                photo={!disableContactPhoto && photo}
                statusTag={statusTag}
                firstName={firstName}
                lastName={lastName}
            />
            <span className="text-[15px] font-medium text-gray-800 truncate max-w-[140px] ">
                {fullName}
            </span>
        </div>
    }

    const handleRedirectToContact = () => {
        dispatch(setRedirectToInteraction({
            contact: interaction.contact?._id,
            interaction: interaction._id
        }));

        const { query: existingQuery } = router;
        const newQuery = { id: interaction.contact._id };

        router.push({
            pathname: Routes.CONTACT,
            query: { ...(existingQuery || {}), ...newQuery }
        })

        if (onClickInteraction) onClickInteraction();
    }

    return (
        <motion.button
            onClick={handleRedirectToContact}
            whileTap={{ scale: 0.99 }}
        >
            <Card className="w-full p-3 hover:bg-gray-50 duration-100 cursor-pointer">
                <CardHeader className="p-0 space-y-1 text-start">
                    <div className="w-full flex items-center justify-between gap-2">
                        <div className="flex items-center max-w-full flex-1 space-x-3">
                            <CardTitle className="text-[16px] font-normal text-gray-800">
                                {GetTitleByInteractionType(interaction)}
                            </CardTitle>
                            {GetContactElement()}
                        </div>
                        <span
                            className="text-gray-500 text-xs truncate"
                            title={dayjs(interaction.createdAt).format('DD/MM/YYYY HH:mm[hs]')}
                        >
                            {
                                formatDistanceToNow(
                                    new Date(interaction.createdAt),
                                    { addSuffix: true, locale: es }
                                )
                            }
                        </span>
                    </div>
                    {GetContentByInteractionType(interaction)}
                </CardHeader>
            </Card>
        </motion.button>
    )
}