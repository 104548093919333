
import { archive, callPhone, closeCallPhone, createNewMeeting, createScheduleMessage, createVisitInteraction, editWhatsAppMessage, sendChannelMessage, sendChannelMultimediaMessage, note as sendNote, sendPersonalWhatsappOnBehalf, sendPersonalWhatsappOnBehalfMultimedia, sendUnofficialWhatsappMessage, sendUnofficialWhatsappMultimediaMessage } from "@/actions/crm/contact"
import { meetingTypes } from "@/components/crm/Modal/meeting/select-meeting-type"
import CardError from "@/components/toaster/card-error"
import { toast } from "@/components/ui/use-toast"
import { callPhoneHandler } from "@/helpers/callPhoneHandler"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import { handleSetLocalStatus, handleUpdate, setEditInteraction, setReply } from '@/redux/slices/crm'
import { handleLocalInteractions, handleUpdateLocalInteraction } from "@/redux/slices/thunks/crm"
import mixpanel from "mixpanel-browser"
import { getTimezone } from '../../../../../../lib/time'

export const createNote = ({ data, options, isRetry = false }) => {
    try {
        const { dispatch, getState } = options
        const { action, id, note, onSuccess, onError, localId: localIdRetry, files } = data || {}
        const state: any = getState()

        const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1
        const socketIgnore = state.crm.socketId

        if (!isRetry) {
            const interactionObject = {
                values: {
                    body: note,
                    files: files || [],
                },
                contact: id,
                via: 'web',
                type: 'note'
            }

            if (action === 'whatsapp-note') {
                interactionObject['values']['channel'] = 'whatsapp'
            }

            dispatch(handleLocalInteractions(interactionObject))
        }

        const timezone = getTimezone();

        sendNote(id, note, action == "whatsapp-note", localId, socketIgnore, files, timezone)
            .then(
                (response) => {
                    if (response.error) {
                        mixpanel.track("Note created Error", {
                            contactId: id,
                            note,
                            channel: action,
                            error: response.error,
                        })

                        onError && onError(response.error)
                        dispatch(handleSetLocalStatus({ localId }))
                        return alert(response.error)
                    } else {
                        for (let event of response.events) dispatch(handleUpdate(event))

                        mixpanel.track("Note created", {
                            contactId: id,
                            note,
                            channel: action,
                        })
                    }

                    onSuccess && onSuccess(response)
                }
            )
            .catch((e) => {
                onError && onError(e)
                dispatch(handleSetLocalStatus({ localId }))
            })
    } catch (error) {
        console.log(error)
    }
}

export const createVisit = async ({ data, options, isRetry = false }) => {
    const { dispatch, getState } = options || {}
    let { id, onSuccess, values, onError, localId: localIdRetry } = data || {}
    values = { ...values, via: "web" }

    const state: any = getState()
    const socketIgnore = state.crm.socketId

    const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1

    if (!isRetry) {
        dispatch(handleLocalInteractions({
            values: {
                ...values,
            },
            contact: id,
            via: 'web',
            type: 'visit'
        }))
    }

    return await createVisitInteraction({ id, body: values, query: { localId, socketIgnore } })
        .then((result) => {
            for (let event of result.events) dispatch(handleUpdate(event))

            mixpanel.track('Visit created',
                {
                    contactId: id,
                    comment: values.comment,
                    status: values.state
                }
            )

            onError && onError(result)
            onSuccess && onSuccess(result)
        })
        .catch((error) => {
            onError && onError(error)
            dispatch(handleSetLocalStatus({ localId }))
        })
}

export const createPhoneCall = async ({ data, options, isRetry = false }) => {
    const { dispatch, getState } = options
    const { id, onSuccess, onError, localId: localIdRetry, phone } = data || {}
    const state: any = getState()
    const socketIgnore = state.crm.socketId
    const isOpenUContact = state.header.isOpenUContact

    const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1

    if (!isRetry) {
        dispatch(handleLocalInteractions({
            values: {
                phone,
                origin: 'agent',
                state: 'calling'
            },
            contact: id,
            via: 'web',
            type: 'phoneCall'
        }))
    }

    callPhoneHandler(phone, id, dispatch, isOpenUContact)
    callPhone(id, phone, { localId, socketIgnore }).then((result) => {
        if ('events' in result) {
            for (let event of result.events) dispatch(handleUpdate(event))
            mixpanel.track("Phonecall created", {
                contactId: id,
                phone: phone,
            })
            onSuccess && onSuccess()
        }
    }).catch((e) => {
        onError && onError()
        dispatch(handleSetLocalStatus({ localId }))
        mixpanel.track("Phonecall error", {
            contactId: id,
            phone: phone,
            error: JSON.stringify(e)
        })
    })
}

export const createCloseCallPhone = async ({ data, options }) => {
    const { dispatch, getState } = options
    const { id, onSuccess, onError, state: phoneCallState, interactionid, fromBackOffice } = data || {}
    const state: any = getState()
    const socketIgnore = state.crm.socketId

    const interactionObj = {
        values: {
            origin: 'agent',
            state: phoneCallState
        },
        id: interactionid,
        contact: id,
        via: 'web',
        type: 'phoneCall'
    }

    dispatch(handleUpdateLocalInteraction(interactionObj))

    return await closeCallPhone(id, interactionid, phoneCallState, { socketIgnore, localId: interactionid, fromBackOffice }).then((result) => {
        if ('events' in result) {
            for (let event of result.events) dispatch(handleUpdate(event))
        }

        mixpanel.track('Phonecall updated', { contactId: id, interactionId: interactionid, state: phoneCallState });
        onSuccess && onSuccess()
    }).catch(() => {
        onError && onError()
        dispatch(handleSetLocalStatus({ localId: interactionid }))
    })
}

export const createMeeting = async ({ data, options, isRetry = false }) => {
    const { dispatch, getState } = options
    const { id, onSuccess, onError, values, localId: localIdRetry, } = data || {}
    const { comment, reminders, meetingType, schedule, whenMeeting, summary } = values || {}

    const state: any = getState()
    const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1
    const socketIgnore = state.crm.socketId

    if (!isRetry) {
        dispatch(handleLocalInteractions({
            values: {
                comment: comment || '',
                meetingType,
                reminders,
                schedule: new Date(schedule).toISOString(),
                isGenerateLink: meetingType !== 'person',
                whenMeeting,
                status: 'active',
                summary: summary || `Reunion vía ${meetingTypes[meetingType].label}`
            },
            contact: id,
            via: 'web',
            type: 'meeting'
        }))
    } else {
        dispatch(
            handleSetLocalStatus({
                localId: localIdRetry,
                status: LocalStatus.LOADING,
            })
        );
    }

    await createNewMeeting({ body: values, contactId: id, query: { localId, socketIgnore } })
        .then((result) => {
            if ('events' in result) {
                for (let event of result.events) dispatch(handleUpdate(event))
                onSuccess && onSuccess()
            }
        })
        .catch(() => {
            onError && onError()
            dispatch(handleSetLocalStatus({ localId }))
        })
}

export const createArchiving = ({ data, options, isRetry = false }) => {
    const { dispatch, getState } = options
    const { id, values, onSuccess, onError, localId: localIdRetry, contactReason } = data || {}
    const { from, to } = values || {}

    const state: any = getState()
    const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1
    const socketIgnore = state.crm.socketId

    if (from !== to) {
        if (!isRetry) {
            dispatch(handleLocalInteractions({
                values: {
                    from,
                    to
                },
                contact: id,
                via: 'web',
                type: 'archiving'
            }))
        } else {
            dispatch(
                handleSetLocalStatus({
                    localId: localIdRetry,
                    status: LocalStatus.LOADING,
                })
            );
        }
    }

    archive(id, to, { localId, socketIgnore })
        .then((result) => {
            if ('events' in result) {
                for (let event of result.events) dispatch(handleUpdate(event))
                onSuccess && onSuccess()
            }

            if (result.error) {
                mixpanel.track('Archive created Error', { contactId: id, reason: to, error: result.error });
            } else {
                mixpanel.track('Archive created', { contactId: id, reason: to });
            }
        })
        .catch((error) => {
            if (error?.body?.error?.error === 24) {
                return toast({
                    duration: 3000,
                    className: "p-3 py-4",
                    customElement: (
                        <CardError
                            title='Error en Archivar Contacto'
                            description={'El contacto ya esta archivado con el motivo seleccionado'}
                        />
                    )
                })
            }

            onError && onError()
            dispatch(handleSetLocalStatus({ localId }))
        })
}

export const createPersonalWpp = async ({ data, options, isRetry = false }) => {
    const { dispatch, getState } = options
    const {
        contactId,
        onSuccess,
        message,
        onError,
        localId: localIdRetry,
        replyMessage,
        file,
        caption,
        fileName,
        type,
        fromBackOffice,
        mimetype,
        extras = {},
        isFromSharedInbox,
        agent
    } = data || {};

    const state: any = getState();

    const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1
    const socketIgnore = state.crm.socketId
    const withMultimedia = file && type

    const query = {
        localId,
        socketIgnore,
        fromBackOffice,
        timezone: getTimezone()
    }

    if (!isRetry) {
        let values: Record<string, string | boolean | undefined> = {
            origin: "agent",
            status: "sending",
            deleted: false,
            message: withMultimedia ? caption : message
        };

        if (replyMessage) values.replyMessage = replyMessage;

        const localInteraction: Record<string, any> = {
            contact: contactId,
            via: 'web',
            type: 'unofficial-whatsapp',
        }

        if (withMultimedia) {
            values = {
                ...values,
                mediaURL: file,
                fileName,
                mediaType: type,
                mimetype,
                media: file,
            }
        }

        localInteraction.values = values
        dispatch(handleLocalInteractions({ ...localInteraction, externalAgent: isFromSharedInbox && agent }))
    }

    let promise;
    const replyMessageId = replyMessage?.id || ''

    if (withMultimedia) {
        const formData = {
            file,
            message,
            caption,
            fileName,
            type,
            contact: contactId,
            mimetype,
            replyMessage: replyMessageId,
            ...extras
        }

        if (isFromSharedInbox) {
            promise = sendPersonalWhatsappOnBehalfMultimedia(agent._id, contactId, formData, query)
        } else {
            promise = sendUnofficialWhatsappMultimediaMessage(formData, contactId, query)
        }
    } else {
        if (isFromSharedInbox) {
            promise = sendPersonalWhatsappOnBehalf(agent._id, contactId, { text: message, replyMessage: replyMessageId }, query)
        } else {
            promise = sendUnofficialWhatsappMessage(contactId, message, replyMessageId, query)
        }
    }

    const withError = () => {
        onError && onError()
        dispatch(handleSetLocalStatus({ localId }))
    }

    dispatch(setReply(null));

    if (!!promise) {
        promise
            .then(
                (response) => {
                    if (response.error) return withError()
                    if ('events' in response) {
                        for (let event of response.events) dispatch(handleUpdate(event))
                    }
                    onSuccess && onSuccess()
                }
            )
            .catch(() => withError())
    }

}

export const createChannelMessage = async ({ data, options, isRetry = false }) => {
    const { dispatch, getState } = options
    const {
        contactId,
        onSuccess,
        message,
        template,
        onError,
        localId: localIdRetry,
        channel,
        fromBackOffice,
        mimetype,
        caption,
        fileName,
        replyMessage,
        extras,
        file,
        type
    } = data || {}

    const state: any = getState()
    const { config, name: channelName, type: channelType } = channel || {}
    const channelId = channel?._id || channel?.id

    const { color } = config || {}
    const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1
    const socketIgnore = state.crm.socketId

    const withMultimedia = file && type
    const isUnOfficialWpp = channelType == 'unofficial_wpp';

    if (isUnOfficialWpp) {
        if (!isRetry) {
            let values: Record<string, string | boolean | undefined> = {
                origin: "agent",
                status: "sending",
                deleted: false,
                channel: channelId,
                channelName,
                channelColor: color,
                message: message,
                medium: channelType,
                replyMessage
            }

            const localInteraction: Record<string, any> = {
                contact: contactId,
                via: 'web',
                type: channelType == 'unofficial_wpp' ? 'unofficial-whatsapp' : 'channelMessage',
            }

            if (withMultimedia && isUnOfficialWpp) {
                values = {
                    ...values,
                    mediaURL: file,
                    fileName,
                    mediaType: type,
                    mimetype,
                    media: file,
                    type
                }
            }

            localInteraction.values = values
            dispatch(handleLocalInteractions(localInteraction))
        }
    }

    const withError = () => {
        onError && onError()
        dispatch(handleSetLocalStatus({ localId }))
    }

    let promise;
    const query = {
        localId,
        socketIgnore,
        fromBackOffice,
        timezone: getTimezone()
    };

    const replyMessageId = replyMessage?.id || '';

    dispatch(setReply(null));

    if (withMultimedia) {
        const formData = {
            file,
            medium: channelType,
            message,
            caption,
            fileName,
            channelid: channelId,
            type,
            contact: contactId,
            mimetype,
            replyMessage: replyMessageId,
            ...extras
        };

        promise = sendChannelMultimediaMessage(formData, contactId, query)
    } else {
        promise = sendChannelMessage(contactId, channelType, message, template, channelId, replyMessageId, query)
    }

    if (!!promise) {
        promise
            .then(
                (response) => {
                    if (response.error) return withError()
                    if ('events' in response) for (let event of response.events) dispatch(handleUpdate(event))
                    onSuccess && onSuccess()
                }
            )
            .catch(() => withError())
    }
}


export const createScheduledMessage = async ({ data, options, isRetry = false }) => {
    const { dispatch, getState } = options
    const state: any = getState()

    const {
        messageContent,
        channel,
        contactId,
        scheduleMessageDate,
        localId: localIdRetry,
        interactionType,
        onSuccess,
        onError,
        isFromSharedInbox,
        agent
    } = data || {}


    const localId = isRetry ? localIdRetry : state.crm.localInteractionId + 1
    const socketIgnore = state.crm.socketId
    const query = { localId, socketIgnore }

    if (!isRetry) {
        const localInteraction: Record<string, any> = {
            contact: contactId,
            via: 'web',
            type: 'scheduled-message'
        }

        let values: Record<string, any> = {
            messageContent,
            interactionType,
            date: scheduleMessageDate,
            channel,
            status: 'active'
        }

        localInteraction['values'] = values
        dispatch(handleLocalInteractions({ ...localInteraction, externalAgent: isFromSharedInbox && agent }))
    }

    await createScheduleMessage(contactId, {
        date: scheduleMessageDate,
        interactionType,
        channel,
        messageContent
    }, isFromSharedInbox && agent?._id, query)
        .then(response => {
            if ('events' in response) for (let event of response.events) dispatch(handleUpdate(event))
            onSuccess && onSuccess(response)
        })
        .catch((e) => {
            onError && onError(e)
            dispatch(handleSetLocalStatus({ localId }))
        })
}

export const updateWhatsAppMessage = async ({ data, options }) => {
    const { contactId, interaction = {}, editedMessage = '' } = data;
    const { dispatch } = options;

    try {
        const interactionObj = {
            values: {
                ...interaction.content,
                message: editedMessage,
                isEdited: true
            },
            id: interaction._id,
            contact: interaction.contact,
            type: interaction.type,
            via: 'web'
        };

        dispatch(handleUpdateLocalInteraction(interactionObj));
        dispatch(setEditInteraction(undefined));

        await editWhatsAppMessage(contactId, interaction._id, editedMessage);
    } catch {
        alert('Error al editar el mensaje');
    }
};