import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import Chip from "@/components/ui/chip";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card";
import { motion } from "framer-motion";
import { AlertCircle } from "lucide-react";
import { useEffect, useState } from "react";

export default function HumanAgentTagButtonAlert() {
    const [animate, setAnimate] = useState(true);

    useEffect(() => {
        setTimeout(() => setAnimate(false), 1000);
    }, []);

    return (
        <HoverCard openDelay={200}>
            <HoverCardTrigger asChild>
                <motion.div
                    animate={animate ? { scale: [1, 1.1, 1], rotate: [0, 4, -4, 0] } : {}}
                    transition={{ duration: 0.5 }}
                >
                    <Button
                        animateClick
                        type="button"
                        variant="none"
                        size="none"
                        onClick={() => {
                            window.open(
                                "https://developers.facebook.com/docs/features-reference/human-agent/?translation",
                                "_blank"
                            );
                        }}
                    >
                        <Chip
                            color="orange"
                            withCircle={false}
                            className="ml-1.5 flex items-center border border-orange-200"
                        >
                            <AlertCircle size={15} />
                            Agente Humano
                        </Chip>
                    </Button>
                </motion.div>
            </HoverCardTrigger>
            <HoverCardContent className="w-max max-w-[400px] p-0">
                <Alert className="bg-yellow-50 border-none">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Etiqueta de Agente Humano</AlertTitle>
                    <AlertDescription className="mt-2">
                        <p className="text-[13px] text-gray-700">
                            Se agregó la etiqueta agente humano a tus mensajes. Por favor, asegúrate de que tu mensaje: 
                        </p>
                        <ul className="list-disc text-[13px] mb-2 pl-6 text-gray-700 mt-2 space-y-1">
                            <li>Cumple con las normas de la comunidad de Facebook</li>
                            <li>No contiene lenguaje ofensivo o discriminatorio</li>
                            <li>Es profesional y respetuoso</li>
                            <li>No incluye spam o contenido engañoso</li>
                            <li>Incluye mensaje importante</li>
                        </ul>
                        <a className="text-primary hover:underline">
                            https://developers.facebook.com/docs/features-reference/human-agent/?translation
                        </a>
                    </AlertDescription>
                </Alert>
            </HoverCardContent>
        </HoverCard>
    );
}