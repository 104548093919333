import { getCompany } from "@/actions/auth";
import { update } from "@/actions/crm/contact";
import { toastActionError } from "@/components/comercial/comercialManagment/comissions/CommisionModal";
import { Button } from '@/components/ui/button';
import { CustomFormField } from "@/components/ui/custom-form-field";
import { Form } from "@/components/ui/form";
import { AppearanceConfig, getAppearance } from "@/helpers/featureFlagHelpers";
import { Contact } from '@/interfaces/crm/contact';
import validatePhoneNumber from "@/lib/validatePhoneNumber";
import { emailValidator } from "@/lib/validators/emailValidator";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import _, { unset } from "lodash";
import { AtSign, Phone, XIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ContactProfilePicture from "../ContactProfilePicture";
import ActionsList from "./ActionsList";
import ContactNameField from "./ContactNameField";
import FieldItem from "./FieldItem";
import { ContactFormValueType, contactSchemaForm, defaultContactValue } from "./helpers/constants";
import { FieldObjectType, formFields } from "./helpers/formFields";
import SelecteableFields from "./SelecteableFields";

interface FancyContactInfoProps { 
    contact: Contact, 
    downChatFn?: () => void, 
    onPressCloseButton?: () => void,
    alwayShowCloseButton?: boolean
};

export default function FancyContactInfo({
    contact,
    downChatFn,
    onPressCloseButton,
    alwayShowCloseButton
}: FancyContactInfoProps) {
    const [fields, setFields] = useState<FieldObjectType[]>([]);
    const [unuseFields, setUnuseFields] = useState<FieldObjectType[]>([])

    const contextForm = useForm<ContactFormValueType>({
        resolver: zodResolver(contactSchemaForm),
        defaultValues: defaultContactValue
    });

    const values = contextForm.watch();
    const disableContactPhoto = useMemo(() => getAppearance<boolean>(AppearanceConfig.DISABLE_CONTACT_PHOTO, false), []);
    const companyPhoneCode = _.get(getCompany(), "country", "uy")

    const fullName = useMemo(() =>
        [values.firstName, values.lastName].filter(Boolean).join(' '),
        [values.firstName, values.lastName]);

    useEffect(() => {
        const formValue = { ...defaultContactValue, ...(contact || {}) };

        let defaultPhonesMetadata = [...(contact?.phonesMetadata || [])];
        const phones = formValue.phones || [];
        let phonesMetadata: Contact['phonesMetadata'] = [];

        phones.forEach((phone: string) => {
            let metadataIndex = defaultPhonesMetadata.findIndex((metadata) => metadata.phone == phone.replace('+', ''));
            let metadata: any = { extensionNumber: '' };

            if (metadataIndex !== -1) {
                metadata = defaultPhonesMetadata[metadataIndex];
                defaultPhonesMetadata.splice(metadataIndex, 1)
            }

            phonesMetadata.push(metadata);
        });

        formValue.phonesMetadata = phonesMetadata;
        contextForm.reset(formValue);

        let fieldsWithoutValue: FieldObjectType[] = [];
        let fieldsWithValue: FieldObjectType[] = [];

        formValue.emails.forEach((__, index) => {
            fieldsWithValue.push({
                label: index == 0 ? 'Email' : `Email ${index + 1}`,
                emptyText: 'Escribe un email...',
                icon: <AtSign size={17} />,
                code: `emails.${index}`,
                fieldType: 'array',
                type: 'email'
            })
        });

        formValue.phones.forEach((__, index) => {
            fieldsWithValue.push({
                label: index == 0 ? 'Teléfono' : `Teléfono ${index}`,
                emptyText: 'Escribe un teléfono...',
                icon: <Phone size={17} />,
                code: `phones.${index}`,
                fieldType: 'array',
                type: 'phone'
            })
        });

        formFields.forEach((field) => {
            let value: string[] | boolean = contextForm.getValues(field.code as 'phones') as string[];
            if (Array.isArray(value)) value = value.length > 0;

            if (value) return fieldsWithValue.push(field);
            fieldsWithoutValue.push(field);
        });

        setFields(fieldsWithValue);
        setUnuseFields(fieldsWithoutValue);
    }, [contact._id]);

    const handleDeleteField = (field: FieldObjectType) => {
        setFields((lastValue) => lastValue.filter((f) => f.code !== field.code));
        const values = contextForm.getValues();

        if (field.code.startsWith('phones.')) {
            unset(values, field.code.replace('phones.', 'phonesMetadata.'));
        };

        unset(values, field.code);
        contextForm.reset(values);
        handleSaveProperties([{ key: field.code, value: null }]);
    }

    const handleAddField = (field: FieldObjectType) => {
        const { code: originalCode, fieldType = 'default', label: originalLabel } = field;
        let updatedField = { ...field };

        if (fieldType === 'array') {
            const currentValues = contextForm.getValues(originalCode as 'phones' | 'emails') || [];
            const newValueIndex = currentValues.length;

            if (originalCode == 'phones') {
                const phonesMetadata = contextForm.getValues('phonesMetadata') || [];
                phonesMetadata.push({ extensionNumber: '' });
                contextForm.setValue('phonesMetadata', phonesMetadata);
            };

            contextForm.setValue(originalCode as 'phones' | 'emails', [...currentValues, ""]);

            updatedField.code = `${originalCode}.${newValueIndex}`;
            updatedField.label = newValueIndex > 0
                ? `${originalLabel} ${newValueIndex + 1}`
                : originalLabel;
        }

        setFields((prevFields) => [...prevFields, updatedField]);
        setUnuseFields((prevFields) =>
            prevFields.filter(({ code }) => code !== originalCode)
        );
    };

    const { mutate } = useMutation({
        mutationFn: (properties: Partial<Contact>) => update(contact._id, properties, '1'),
        onError: toastActionError
    });

    const handleSaveProperties = (fields: { key: string, value?: any }[]) => {
        let properties: Partial<Contact> = {};

        fields.forEach(({ value, key }) => {
            if (key.startsWith('emails')) {
                const allEmails = contextForm.getValues('emails').map((email) => {
                    const isValidEmail = emailValidator(email);
                    return isValidEmail ? email : null
                }).filter(Boolean) as string[];

                properties.emails = allEmails;
            } else if (key.startsWith('phones')) {
                const phones = (contextForm.getValues('phones') || []).map((phone: string) => {
                    if (!phone) return null;
                    const validatedPhoneNumber = validatePhoneNumber(phone, companyPhoneCode);
                    if (!validatedPhoneNumber?.valid) return null;
                    return String(validatedPhoneNumber.number);
                }).filter(Boolean) as string[];

                const phonesMetadata = (contextForm.getValues('phonesMetadata') || []).filter(Boolean);

                const updatedPhonesMetadata = phones.map((phone: string, index: number) => {
                    const metadata = phonesMetadata[index];
                    return {
                        extensionNumber: metadata?.extensionNumber || '',
                        phone
                    }
                });

                properties.phonesMetadata = updatedPhonesMetadata;
                properties.phones = phones;
            } else {
                properties[key] = value;
            }
        });

        if (Object.keys(properties).length == 0) return;
        mutate(properties);
    }

    return <div className="h-full w-full bg-white flex flex-col border-l relative">
        {
            onPressCloseButton && (
                <Button
                    size={'icon'}
                    variant="ghost"
                    className={`absolute flex ${!alwayShowCloseButton ? 'xl:hidden' : ''} top-3 left-3 z-10`}
                    type="button"
                    onClick={onPressCloseButton}
                >
                    <XIcon size={18} />
                </Button>
            )
        }
        <div className="absolute top-0 left-0 w-full pb-4 h-full overflow-y-auto">
            <Form {...contextForm}>
                <form>
                    <div className="w-full flex items-center px-6 justify-center pt-5 flex-col">
                        <ContactProfilePicture
                            lastName={values.lastName}
                            statusTag={values.statusTag}
                            firstName={values.firstName}
                            className="w-[64px] text-xl h-[64px]"
                            photo={!disableContactPhoto ? values.photo : ''}
                        />
                        <ContactNameField
                            fullName={fullName}
                            contextForm={contextForm}
                            onChangeValues={({ firstName, lastName }) => {
                                handleSaveProperties([
                                    { key: 'lastName', value: lastName },
                                    { key: 'firstName', value: firstName },
                                ])
                            }}
                        />
                        <ActionsList
                            downChatFn={downChatFn}
                            contact={contact}
                        />
                    </div>
                    <div className="flex flex-col gap-1.5 pt-7 overflow-hidden px-2">
                        {
                            fields.map((formField) => {

                                const allValues = (formField.fieldType == 'array')
                                    ? contextForm.getValues(formField.code.split('.')[0] as 'phones')
                                    : undefined;

                                return (
                                    <CustomFormField
                                        control={contextForm.control}
                                        name={formField.code}
                                        key={formField.code}
                                        fnElement={({ field }) => {
                                            return (
                                                <FieldItem
                                                    field={formField}
                                                    contact={contact}
                                                    value={field.value}
                                                    fieldValue={allValues}
                                                    companyPhoneCode={companyPhoneCode}
                                                    handleDeleteField={() => handleDeleteField(formField)}
                                                    onChangeValue={(value) => {
                                                        if (value == field.value) return;
                                                        field.onChange(value);
                                                        handleSaveProperties([{
                                                            key: formField.code,
                                                            value
                                                        }])
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                )
                            })
                        }
                        <SelecteableFields
                            fields={unuseFields}
                            handleAddField={handleAddField}
                        />
                    </div>
                </form>
            </Form>
        </div>
    </div>
}