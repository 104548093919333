import { cn } from "@/lib/utils";
import dayjs from "dayjs";
import es from 'dayjs/locale/es';
import { motion } from 'framer-motion';
import { ChevronRight } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import useDebounce from "../core/functions/useDebouce";
import { Button } from "../ui/button";

dayjs.locale(es)

export interface CarouselDateProps {
    onHandleChangeDate?: (date: Date) => void,
    onClickDateValue?: () => void
    classNameButtonValue?: string,
    classNameContainer?: string,
    date?: Date,
    maxDate?: Date
}

export default function CarouselDate({
    classNameButtonValue,
    onHandleChangeDate,
    onClickDateValue,
    date,
    maxDate,
    classNameContainer
}: CarouselDateProps) {
    const [localValue, setLocalValue] = useState(date || new Date());
    const debounceValue = useDebounce(localValue, 300)

    const dateUse = localValue;

    const dateTime = useMemo(() => maxDate && dayjs(maxDate).startOf('day').toDate().getTime(), [maxDate])
    const isDisabledNextButton: boolean = !!(maxDate && (dateTime as number) <= dateUse.getTime())

    useEffect(() => {
        onHandleChangeDate && onHandleChangeDate(debounceValue as Date)
    }, [debounceValue])

    const onChangeDate = (type: 'next' | 'prev') => () => {
        let dateToDays = dayjs(dateUse)
        if (type == 'next') {
            if (maxDate && dateUse.getTime() >= (dateTime as number)) return;
            dateToDays = dateToDays.add(1, 'day')
        } else {
            dateToDays = dateToDays.subtract(1, 'day')
        }

        const dayjsToDate = dateToDays.toDate()
        setLocalValue(dayjsToDate)
    }

    const { formatDate, numberDate } = useMemo(() => {
        const dateToDays = dayjs(dateUse);
        const currentDate = dayjs();
        const isDifferentMonthYear = dateToDays.month() !== currentDate.month() || dateToDays.year() !== currentDate.year();

        return {
            formatDate: isDifferentMonthYear ? dateToDays.format('dddd - MM/YYYY') : dateToDays.format('dddd'),
            numberDate: dateToDays.date()
        }
    }, [dateUse]);

    return (
        <div className={cn("border px-4 py-2 flex items-center rounded-md justify-center relative", classNameContainer)}>
            <ArrowButton
                classNameContent="absolute left-1"
                onClick={onChangeDate('prev')}
                left={true}
            />
            <div className="flex text-center flex-col items-center justify-center">
                <motion.p
                    key={formatDate}
                    className="text-gray-600 capitalize select-none text-[13px]"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.3 }}
                >
                    {formatDate}
                </motion.p>
                <button
                    onClick={onClickDateValue}
                    className={cn(classNameButtonValue)}
                >
                    <motion.p
                        key={numberDate}
                        className="font-medium text-gray-900 select-none text-[19px]"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.3 }}
                    >
                        {numberDate}
                    </motion.p>
                </button>
            </div>
            <ArrowButton
                disabled={isDisabledNextButton}
                classNameContent={`absolute right-1`}
                onClick={onChangeDate('next')}
            />
        </div>
    )
}

interface ArrowButtonProps {
    className?: string,
    left?: boolean,
    disabled?: boolean
    classNameContent?: string
    onClick: () => void
}

export function ArrowButton({
    className,
    left = false,
    disabled = false,
    onClick,
    classNameContent
}: ArrowButtonProps) {
    const [isPressed, setIsPressed] = useState<boolean>(false);
    const durationRef = useRef<number>(300);
    const velocityRef = useRef<number>(20);

    useEffect(() => {
        if (!isPressed) return;

        const executeClick = () => {
            onClick?.();
            if (durationRef.current > 50) {
                durationRef.current -= velocityRef.current;
                velocityRef.current += 2

            }
            interval = setTimeout(executeClick, durationRef.current);
        };

        let interval = setTimeout(executeClick, durationRef.current);
        return () => clearTimeout(interval);
    }, [isPressed, onClick]);

    const handleMouseDown = () => setIsPressed(true);

    const handleSetFalsePressed = () => {
        setIsPressed(false);
        durationRef.current = 300;
        velocityRef.current = 20
    };

    return (
        <div className={cn(classNameContent)}>
            <motion.div
                whileTap={{ scale: 0.9 }}
                initial={{ scale: 1 }}
                animate={{ scale: isPressed ? 0.95 : 1 }}
                transition={{ duration: 0.1 }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleSetFalsePressed}
                onMouseLeave={handleSetFalsePressed}
            >
                <Button
                    onClick={onClick}
                    variant={'ghost'}
                    disabled={disabled}
                    className={cn('text-primary w-7 h-7 rounded-full shrink-0', className)}
                    size={'icon'}
                >
                    <ChevronRight size={18} className={`${left ? 'rotate-180' : ''}`} />
                </Button>
            </motion.div>
        </div>
    );
}