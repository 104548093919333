import { getCompany } from "@/actions/auth";
import { FileIcon } from "@/components/crm/Bubble/Icons/Crm";
import StatusTagCircle from "@/components/crm/StatusTag/status-tag-circle";
import { getFileType } from "@/components/crm/uploadMultimedia/utils/sendMultimediaWithPersonalWhatsApp";
import { StyledLinkifyerLines } from "@/components/StyledLinkifyer";
import {
    CardDescription
} from "@/components/ui/card";
import { formatFileSize } from "@/helpers/formatFileSize";
import { getStatusTagByCode } from "@/helpers/tagColorToPalette";
import { getArchivingReason } from "@/hooks/useGetArchivingReason";
import { API_WPP } from "@/httpConfig";
import { InteractionType } from '@/interfaces/Interface';
import { cn } from "@/lib/utils";
import dayjs from "dayjs";
import _ from "lodash";
import { Headphones, SquarePlay } from "lucide-react";
import { PropsWithChildren, ReactNode } from "react";

export const GetTitleByInteractionType = (interaction: InteractionType) => {
    switch (interaction.type) {
        case 'note': {
            return (!interaction.content.body && (interaction.content.files || []).length > 0)
                ? 'Nota con archivos adjuntos'
                : 'Nota escrita a'
        }
        case 'tag':
        case 'tagChange': {
            return (!interaction.content.to)
                ? 'Etiqueta removida'
                : 'Etiqueta agregada'
        }
        case 'phoneCall': {
            const callStates: Record<string, string> = {
                calling: 'En llamada con',
                cancel: 'Llamada cancelada',
                done: 'Llamada finalizada',
                voicemail: 'Llamada enviada a buzón de voz',
            };

            return callStates[interaction.content.state] || '';
        }
        case 'unofficial-whatsapp': {
            return 'Mensaje de WhatsApp'
        }
        case 'channelMessage': {
            const mediumLabels: Record<string, string> = {
                fb: 'Mensaje de Facebook',
                ig: 'Mensaje de Instagram',
                leads: 'Nuevo lead de',
                wpp: 'Mensaje de WhatsApp',
            };

            return mediumLabels[interaction.content.medium] || '';
        }
        case 'contactTaken': {
            return 'Tomaste al contacto'
        }
        case 'reminder': {
            if (!interaction.content.reminderDate) return (interaction.via === 'server')
                ? 'Recordatorio vencido'
                : 'Cancelaste el recordatorio a';

            return 'Recordatorio agregado a';
        }
        case 'archiving': return interaction.content.to ? 'Archivaste a' : 'Desarchivaste a'
        case 'transfer': return 'Transferencia de'
        case 'meeting': return "Reunión con"
        case 'visit': return 'Visita a'
        case 'email': return 'Correo enviado a'
        case 'subconversation-closed': return 'Subconversación cerrada'
        case 'subconversation-created': return 'Subconversación creada'
        case 'disregarded': return 'Contacto desatendido'
        case 'meeting-reminder': return 'Recordatorio de reunión con'
        case 'expired-meeting': return 'Reunión expirada con'
        case 'sale': return 'Registro de venta'
        case 'smsMessage': return 'Mensaje de texto escrito a'
        case 'saleupdate': {
            const messages = {
                created: 'Creaste una solicitud',
                'state-change': 'Cambiaste el estado de una solicitud',
                updated: 'Modificaste una solicitud',
                comment: 'Dejaste un comentario en una solicitud'
            };

            return messages[interaction?.content?.type] || 'Acción no reconocida';
        }
        case 'scheduled-message': {
            if (interaction.content.interactionType == 'email') return 'Se agregó un recordatorio de email a'
            return 'Se agregó un recordatorio de mensaje a'
        }
    }
}

export const GetContentByInteractionType = (interaction: InteractionType) => {
    switch (interaction.type) {
        case 'archiving': {
            const archivingCode = interaction.content.to || interaction.content.from
            const archivingReason = getArchivingReason(archivingCode)?.name || archivingCode;

            return (
                <Description>
                    {
                        !interaction.content.to
                            ? `Removiste la razón de archivado "${archivingReason}" del contacto`
                            : `Con la razón "${archivingReason}"`
                    }
                </Description>
            );
        }
        case 'tag':
        case 'tagChange': {
            const renderTagChange = (from: string, to: string) => {
                const lastTag = getStatusTagByCode(from);
                const newTag = getStatusTagByCode(to);

                if (!to) return <>
                    Eliminaste la etiqueta <TagDisplay
                        tag={lastTag?.name || ""}
                        code={from}
                    />
                </>;

                if (!from) {
                    return (
                        <>
                            Agregaste la etiqueta <TagDisplay
                                tag={newTag?.name || ""}
                                code={to}
                            />
                        </>
                    );
                }

                return (
                    <>
                        Cambiaste la etiqueta <TagDisplay
                            tag={lastTag?.name || ""}
                            code={from}
                        /> a <TagDisplay
                            code={to}
                            tag={newTag?.name || null}
                        />
                    </>
                );
            };
            return (
                <Description className="flex gap-1 items-center">
                    {renderTagChange(interaction.content.from, interaction.content.to)}
                </Description>
            );
        }
        case 'note': {
            return (
                <MessageContainer
                    text={interaction.content.body}
                    files={(interaction.content.files || []).map((file) => {
                        return {
                            ...file,
                            mimetype: file.mimeType
                        }
                    })}
                />
            )
        }
        case 'visit': {
            let visitText = _.get(getCompany(), "visitResults", []).find((result) => {
                return result.code == interaction.content.state
            })?.name || interaction.content.state;

            return <Description>
                {visitText}
            </Description>
        }
        case 'transfer': {
            const fullName = [interaction.content?.toUser?.name, interaction.content?.toUser?.lastname]
                .filter(Boolean)
                .join(' ');

            return (
                <Description>
                    {fullName ? `A ${fullName}` : 'Nombre no disponible'}
                </Description>
            )
        }
        case 'scheduled-message': {
            return (
                <Description>
                    {(interaction as any).content?.emailContent || (interaction as any).content?.messageContent || ''}
                </Description>
            )
        }
        case 'unofficial-whatsapp': {
            let mediaUrl = interaction.content.media

            if (mediaUrl && !mediaUrl.includes("https://")) {
                mediaUrl = API_WPP + "/crm/wppimg/" + mediaUrl
            }

            return (
                <MessageContainer
                    text={interaction.content.message}
                    files={(interaction.content.mediaType && mediaUrl) ? [{
                        type: (interaction.content.mediaType || 'file') as 'file',
                        name: interaction.content.fileName || interaction.content.message || (interaction.content.mediaType == 'audio' ? 'Nota de audio' : ''),
                        url: mediaUrl || ''
                    }] : []}
                />
            )
        }
        case 'channelMessage': {
            return (
                <MessageContainer
                    text={interaction.content.message.text}
                    files={interaction.content.message.media ? [{
                        type: (interaction?.content?.message?.type || 'file') as 'file',
                        name: interaction?.content?.message?.fileName || '',
                        url: interaction?.content?.message?.media || '',
                    }] : []}
                />
            )
        }
        case 'reminder': {
            if (!interaction.content.reminderDate) return null;
            return (
                <Description>
                    Para el {dayjs(interaction.content.reminderDate).format('DD/MM/YYYY HH:mm[hs]')}
                </Description>
            )
        }
        case 'contactTaken': {
            return (
                <Description>
                    A las {dayjs(interaction.createdAt).format('HH:mm[hs]')}
                </Description>
            )
        }
        case 'smsMessage': {
            return (<Description>
                {interaction.content.message}
            </Description>)
        }
        case 'meeting': {
            <Description>
                Para el día {dayjs(interaction.content.schedule).format('DD/MM/YYYY HH:mm')}
            </Description>
        }
        default: return null
    }
}


const TagDisplay = ({ code, tag }: { code: string, tag: string | null }) => {
    if (!tag) return <>{code}</>;

    return <span className="flex items-center">
        <StatusTagCircle
            code={code}
            className="inline-block relative mx-1 w-[5px] h-[5px]"
        />
        {tag}
    </span>
}


export function Description({ children, className }: PropsWithChildren & { className?: string }) {
    return (
        <CardDescription className={cn("text-gray-500", className)}>
            {children}
        </CardDescription>
    )
}

type FileType = {
    type?: 'audio' | 'file' | 'image' | 'video' | 'sticker',
    mimetype?: string,
    name: string,
    size?: number,
    url: string
}

interface MessageContainerProps {
    text?: string,
    files?: FileType[]
}

export function MessageContainer({ text, files = [] }: MessageContainerProps) {
    return (
        <div className="flex flex-col space-y-2">
            {text && (
                <Description>
                    <StyledLinkifyerLines text={text} />
                </Description>
            )}
            {
                (files.length > 0) && (
                    <div className="flex gap-2 flex-wrap">
                        {files.map((file, index) => (
                            <FileRenderer key={index} file={file} />
                        ))}
                    </div>
                )
            }
        </div>
    );
}

function FileRenderer({ file }: { file: FileType }) {
    let fileType = file.type || getFileType(file.mimetype || 'application/octet-stream');

    switch (fileType) {
        case 'file':
            return (
                <FileCard
                    icon={<FileIcon className="w-[25px] h-[25px]" />}
                    name={file.name}
                    size={file.size}
                />
            );
        case 'sticker':
        case 'image':
            return <MediaPreview type="image" url={file.url} />;
        case 'video':
            return <MediaPreview type="video" url={file.url} />;
        case 'audio':
            return (
                <FileCard
                    icon={<Headphones size={20} className="text-white" />}
                    name={file.name}
                    size={file.size}
                    bgColor="bg-orange-500"
                />
            );
        default:
            return null;
    }
}

function FileCard({ icon, name = 'Sin nombre', size, bgColor = 'bg-gray-50' }: {
    icon: ReactNode,
    bgColor?: string,
    name: string,
    size?: number,
}) {
    return (
        <div className="w-max min-w-[100px] h-max flex items-center gap-2 bg-[#f3f4f5] p-2 rounded-md overflow-hidden relative pr-5 border">
            <div className={`w-[40px] h-[40px] flex items-center justify-center shrink-0 border rounded-sm ${bgColor}`}>{icon}</div>
            <div>
                <h5 className="text-[13.5px] font-medium text-gray-800">
                    {name || "Sin nombre"}
                </h5>
                <p className="text-[12.5px] text-gray-500">
                    {size ? formatFileSize(size) : "Tamaño desconocido"}
                </p>
            </div>
        </div>
    );
}

function MediaPreview({ type, url }: { type: 'image' | 'video', url: string }) {
    return (
        <div className="w-[75px] h-[75px] shrink-0 border relative overflow-hidden rounded-sm bg-gray-200">
            {type == 'video' && (
                <span className="absolute text-white top-1 right-1">
                    <SquarePlay size={18} />
                </span>
            )}
            {type === 'image' ? (
                <img className="w-full h-full object-cover" src={url} alt="image preview" />
            ) : (
                <video className="w-full h-full object-cover" controls={false} src={url} />
            )}
        </div>
    );
}

