import {
    Card,
    CardHeader
} from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

export default function ActivityInteractionSkeleton() {
    return (
        <Card className="w-full p-3">
            <CardHeader className="p-0 space-y-1 text-start">
                <div className="w-full flex items-center justify-between gap-2">
                    <div className="flex items-center max-w-full flex-1 space-x-3">
                        <Skeleton className="max-w-[150px] h-3 rounded-sm w-full" />
                        <div className="flex items-center gap-2">
                            <Skeleton className="w-[25px] shrink-0 h-[25px] rounded-full" />
                            <Skeleton className="w-[60px] h-2 rounded-sm shrink-0" />
                        </div>
                    </div>
                    <Skeleton className="max-w-[90px] h-2 rounded-sm w-full" />
                </div>
                <Skeleton className="max-w-[200px] h-2 rounded-sm w-full" />
                <Skeleton className="max-w-[150px] h-2 rounded-sm w-full" />
            </CardHeader>
        </Card>
    )
}
