"use client"
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import { motion } from "framer-motion"
import { AlertCircle, SquareArrowOutUpRight } from "lucide-react"
import { FaFacebook, FaInstagram } from "react-icons/fa"

export default function GracePeriodAlert({ medium, handleRedirect }: { medium: 'ig' | 'fb', handleRedirect: () => void }) {

    const isFacebook = medium === 'fb'

    return (
        <div className="h-full w-full flex items-center bg-white justify-center">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                <Card className="w-full max-w-[540px] border-0">
                    <CardContent className="pt-6">
                        <div className="flex flex-col items-center text-center p-4">
                            <div className="bg-blue-100 p-3 rounded-full mb-4">
                                <AlertCircle className="h-7 w-7 text-blue-600" />
                            </div>

                            <h2 className="text-[18px] font-semibold text-gray-800 mb-2">Te encuentras fuera del periodo permitido por Meta</h2>

                            <p className="text-gray-500 text-[13px] mb-6">
                                Según las políticas impuestas por Meta, solo puedes responder dentro de los 7 días posteriores al último mensaje del cliente.
                                Una vez pasado este plazo, Meta bloquea la conversación y ya no permite responder.
                            </p>

                            <Button
                                onClick={handleRedirect}
                                className={cn(
                                    "w-max relative transition-all duration-300 flex items-center justify-center gap-2",
                                )}
                            >
                                {isFacebook ? <FaFacebook className="w-5 h-5" /> : <FaInstagram className="w-5 h-5" />}
                                <span className="font-medium">{isFacebook ? "Contactar por Facebook" : "Contactar por Instagram"}</span>
                                <SquareArrowOutUpRight className="w-4 h-4 ml-1" />
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </motion.div>
        </div>
    )
}
