import { LoadingButton } from '@/components/buttons/loading-button';
import { Button } from '@/components/ui/button';
import { Form, FormField } from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { deleteElementOfArrayByInx } from '@/helpers/arrayFunctions';
import { uploadFileToS3ByObject } from '@/helpers/uploadFileToS3';
import useLoadingAndError from '@/hooks/useLoadingAndError';
import { useToggle } from '@/hooks/useToggle';
import useUploadFile from '@/hooks/useUploadFile';
import { setScheduleMessageDate, setUploadMultimediaType } from '@/redux/slices/crm';
import '@/styles/scrollModify.css';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { CalendarClock, SendHorizontal } from 'lucide-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import ScheduledModal from '../ChatInput/ScheduledModal';
import InputMultimedia from '../Inputs/input-multimedia';
import { createInteraction } from '../Prospect/libs/helpers/interaction';
import { BtnAddFile } from './btn-add-file';
import { defaultValueForEditor, handleChangeFile, zContextSchema } from './constants';
import ElementBySelectedType from './element-by-selected-type';
import HeaderMultimedia from './header-multimedia';
import { ItemMultimedia } from './item-multimedia';
import { getFileType, sendMultimediaWithPersonalWhatsApp } from './utils/sendMultimediaWithPersonalWhatsApp';
import { sendNoteWithFile } from './utils/sendNoteWithFiles';

export default function UploadMultimedia({ prospect }) {
    const [openScheduledMessage, toggleScheduledMessage] = useToggle<boolean>(false)
    const [fileSelected, setFileSelected] = useState(0)
    const [isEditMode, toggleEditMode, setEditMode] = useToggle<boolean>(false)
    const multimediaToSend = useSelector((state: any) => state.crm.multimediaToSend)
    const [editFiles, setEditFiles] = useState<Array<any>>([])
    const { isLoading, setError, setIsLoading } = useLoadingAndError()

    const editorsRefs = useRef<AvatarEditor>([])
    const editorRef = useRef<AvatarEditor | null>(null)

    const activeChannel = useSelector((state: any) => state.crm.activeChannel)
    const activeAction = useSelector((state: any) => state.crm.action)
    const scheduleMessageDate = useSelector((state: any) => state.crm.scheduleMessageDate);

    const dispatch = useDispatch()

    const { getInputProps, open, result, filesRemoves, isLoading: isLoadingUploadFile } = useUploadFile({
        addFile: async () => defaultValueForEditor
    })

    const { toast } = useToast();

    const contextForm = useForm<z.infer<typeof zContextSchema>>({
        resolver: zodResolver(zContextSchema),
        defaultValues: {
            multimedia: [],
            noteBody: undefined
        }
    })

    const allMultimedia = contextForm.watch('multimedia')
    const fileSelect = useMemo(() => editFiles[fileSelected], [fileSelected, editFiles, allMultimedia])

    useEffect(() => {
        if (!!filesRemoves && filesRemoves > 0) {
            toast({
                title: 'Archivos removidos',
                description: `${filesRemoves}${filesRemoves >= 2 ? ' archivos han sido removidos' : ' archivo ha  sido removido'} debido a que su tamaño supero el limite`,
                duration: 3000,
            })
        }

        if (result) {
            if (isEditMode) toggleEditMode()
            const newValue = [...contextForm.getValues('multimedia'), ...result]

            setEditFiles(lastValue => [...lastValue, ...result])
            setFileSelected(newValue.length - 1)
            contextForm.setValue('multimedia', newValue as any)
        }
    }, [result])

    useEffect(() => {
        setFileSelected(multimediaToSend.multimedia.length - 1)
        setEditFiles(multimediaToSend.multimedia)
        contextForm.setValue('multimedia', multimediaToSend.multimedia)
    }, [multimediaToSend.multimedia])

    const fnDebounce = useCallback(async (position, inx) => {
        const allValues = _.cloneDeep(editFiles)
        allValues[inx].position = position
        setEditFiles(allValues)
    }, [editFiles])

    const onChangeScheduleDate = (date: string | null) => dispatch(setScheduleMessageDate(date))

    const handleCLose = () => {
        onChangeScheduleDate(null)
        dispatch(setUploadMultimediaType({
            type: null,
            multimedia: []
        }))
    }

    const handleDelete = useCallback((inx) => {
        const lengthOfFile = allMultimedia.length

        if (lengthOfFile - 1 <= 0) return handleCLose()
        if (inx === lengthOfFile - 1) setFileSelected(lastValue => lastValue - 1)

        if (fileSelected == lengthOfFile - 1 && fileSelected !== 0) {
            setFileSelected(lengthOfFile - 2)
        }

        setEditFiles(lastValue => deleteElementOfArrayByInx<Array<any>>(lastValue, inx))
        contextForm.setValue('multimedia', deleteElementOfArrayByInx<Array<any>>(allMultimedia as any, inx) as any)
    }, [allMultimedia, allMultimedia.length, fileSelected, editFiles, editFiles.length, scheduleMessageDate])

    let selectActionType = activeAction?.type ?? activeAction
    const enabledScheduledMesage = (selectActionType === 'channel-chat' && activeChannel.type === 'unofficial_wpp') || selectActionType == 'unofficial-whatsapp'

    console.log('SELECT ACTION TYPE:', selectActionType);

    const handleSendFiles = useCallback(async (values: z.infer<typeof zContextSchema>) => {
        setIsLoading(true)
        const { multimedia: files, noteBody } = values
        const contact = prospect._id

        const filesWithS3Url = await uploadFileToS3ByObject(files as any)
        let promise;

        if (enabledScheduledMesage && scheduleMessageDate) {
            for await (let file of filesWithS3Url) {
                const { name, path, type, caption }: any = file || {}
                const mediaType = getFileType(type)

                await createInteraction({
                    dispatch,
                    type: 'scheduled-message',
                    data: {
                        scheduleMessageDate,
                        interactionType: 'unofficial-whatsapp',
                        channel: (activeAction !== 'unofficial-whatsapp') ? activeChannel?._id : undefined,
                        onSuccess: () => handleCLose(),
                        onError: (e) => setError(e),
                        contactId: contact,
                        messageContent: {
                            message: caption,
                            media: path,
                            filename: name,
                            mimetype: type,
                            mediaType
                        }
                    }
                })
            }

            return;
        }

        switch (selectActionType) {
            case 'note': {
                promise = sendNoteWithFile({
                    action: activeAction,
                    contactId: contact,
                    note: noteBody as string,
                    files: filesWithS3Url as any,
                    dispatch
                })
            }
                break;
            case "channel-chat":
            case 'unofficial-whatsapp': {
                promise = sendMultimediaWithPersonalWhatsApp({
                    files: filesWithS3Url as any,
                    channel: activeChannel,
                    contactId: contact,
                    medium: selectActionType,
                    dispatch
                })
                break;
            }
        }

        if (promise) {
            promise
                .then(() => handleCLose())
                .catch((e) => setError(e))
                .finally(() => setIsLoading(false))
        }
    }, [allMultimedia, editorsRefs, activeChannel, enabledScheduledMesage, scheduleMessageDate]);

    const handleSelectFile = (inx: number) => {
        if (isEditMode) setEditMode(false)
        setFileSelected(inx)
    }

    const handleSaveChanges = () => {
        const file = _.cloneDeep(contextForm.getValues(`multimedia.${fileSelected}`))

        handleChangeFile(file, editorRef)
            .then(result => {
                contextForm.setValue(`multimedia.${fileSelected}`, result as any)
                toggleEditMode()
            })
    }

    return <React.Fragment>
        <ScheduledModal
            open={!!openScheduledMessage}
            onOpenChange={toggleScheduledMessage}
            onSuccess={onChangeScheduleDate}
        />
        <div className="w-full h-full flex flex-col absolute bg-crm top-0 z-10 left-0">
            <HeaderMultimedia
                handleEditMode={toggleEditMode}
                isEditMode={isEditMode}
                inxOfFile={fileSelected}
                handleSaveChanges={handleSaveChanges}
                values={editFiles}
                onChangeValue={(values) => setEditFiles(values)}
            />
            {
                fileSelect
                    ? <Form {...contextForm}>
                        <form onSubmit={contextForm.handleSubmit(handleSendFiles)} className='flex-grow flex flex-col' >
                            <div className='flex-grow gap-5 my-4 relative justify-center h-[100%] px-5 py-6 w-full items-center  flex-col flex' >
                                <div className='absolute top-0 left-0 w-full h-full'>
                                    <ElementBySelectedType
                                        isEditMode={isEditMode}
                                        realPath={allMultimedia[fileSelected]?.path}
                                        fileSelect={fileSelect}
                                        fnDebounce={fnDebounce}
                                        editorRef={refEditor => refEditor && (editorRef.current = refEditor)}
                                        indexElement={fileSelected}
                                        type={fileSelect?.type || ''}
                                    />
                                </div>
                            </div>
                            <div className='shrink-0 h-[150px]'>
                                <div className='flex md:px-8 px-6 mb-4 gap-3 items-center justify-between w-full'>
                                    {activeAction !== 'note' ? (
                                        <FormField
                                            name={`multimedia.${fileSelected}.caption`}
                                            control={contextForm.control}
                                            render={({ field }) => <InputMultimedia
                                                value={allMultimedia[fileSelected]?.caption || ''}
                                                handleChange={field.onChange}
                                            />
                                            }
                                        />
                                    ) : (
                                        <FormField
                                            name={`noteBody`}
                                            control={contextForm.control}
                                            render={({ field }) => <InputMultimedia
                                                value={field.value}
                                                handleChange={field.onChange}
                                                placeholder={'Escribe una nota...'}
                                            />
                                            }
                                        />
                                    )}
                                    <Button size="icon" variant="ghost" type={'button'} className={"shrink-0"} onClick={toggleScheduledMessage}>
                                        <CalendarClock
                                            size={22}
                                            className={`${scheduleMessageDate ? 'text-primary' : 'text-gray-700'} `}
                                        />
                                    </Button>
                                    <LoadingButton
                                        size={'icon'}
                                        type='submit'
                                        variant={'ghost'}
                                        loading={isLoading}
                                    >
                                        <SendHorizontal size={22} className="text-gray-700" />
                                    </LoadingButton>
                                </div>
                                <ul className='flex items-start gap-2 w-full m-auto justify-center  overflow-x-auto px-4'>
                                    <div className='overflow-x-auto flex items-center modifyScroll gap-2 md:max-w-[70%] max-w-[75%]'>
                                        {
                                            allMultimedia.map((file, inx) => (
                                                <ItemMultimedia
                                                    file={file}
                                                    handleDelete={() => handleDelete(inx)}
                                                    handleSelectFile={() => handleSelectFile(inx)}
                                                    isSelect={inx === fileSelected}
                                                    key={file.name}
                                                />
                                            ))
                                        }
                                    </div>
                                    <BtnAddFile open={open} isLoading={isLoadingUploadFile} />
                                    <input {...getInputProps()} hidden />
                                </ul>
                            </div>
                        </form>
                    </Form>
                    : <></>
            }
        </div>
    </React.Fragment>
}