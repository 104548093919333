import useDebounce from '@/components/core/functions/useDebouce';
import { CreateContactFormType } from '@/components/crm/ContactDrawer/CreateContactDrawer';
import { toast } from '@/components/ui/use-toast';
import { Feature, getFeatureCompany } from '@/helpers/featureFlagHelpers';
import { fetcher } from '@/helpers/fetcher';
import { ExternalSystemConfig } from '@/interfaces/Interface';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export type OperationType = 'creation' | 'modification';

interface ValidationResponse {
    isValid: boolean,
    errorMessage?: string,
    operationType: OperationType
}

export default function useCreateContactInExternalSystem(contactValue: CreateContactFormType, enabled: boolean = false) {
    const [operationType, setOperationType] = useState<OperationType>('creation');
    const [isValidOperation, setIsValidOperation] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [isLoadingOperation, setIsLoadingOperation] = useState<boolean>(false);

    const externalSystemConfig: ExternalSystemConfig = getFeatureCompany<ExternalSystemConfig>(Feature.EXTENAL_SYSTEMS_CONFIG, {})
    const extenalContactCreationConfig = externalSystemConfig?.contactValidation || {};

    const isEnabledExternalSystemOperation = extenalContactCreationConfig?.active && extenalContactCreationConfig?.createUrl && extenalContactCreationConfig?.updateUrl && extenalContactCreationConfig?.validateUrl

    const debouncedValue = useDebounce(JSON.stringify(contactValue), 400);

    const handleValidationResponse = (response: ValidationResponse) => {
        try {
            const { errorMessage = '', operationType, isValid = false } = response || {}
            setError(errorMessage);
            setIsValidOperation(isValid);
            setOperationType(operationType);
        } catch (error) {
            console.error('Has error on handle response of validation:', error)
        }
    };

    const handleResetValidations = () => {
        setError('');
        setIsValidOperation(false);
        setOperationType('creation');
    };

    const { mutate: mutateValidation, isPending: isLoadingValidation } = useMutation({
        mutationFn: async (values: CreateContactFormType) => {
            return fetcher<ValidationResponse>({
                url: extenalContactCreationConfig?.validateUrl || '',
                defaultContentType: true,
                isExternalURL: true,
                withToken: true,
                method: 'POST',
                body: values
            })
        },
        onSuccess: handleValidationResponse,
        onError: (error: { body: ValidationResponse }) => {
            handleValidationResponse(error?.body)
        }
    });

    useEffect(() => handleResetValidations(), [enabled])

    useEffect(() => {
        if (!enabled) return;

        if (isEnabledExternalSystemOperation) {
            handleResetValidations();
            mutateValidation(contactValue);
        }
    }, [debouncedValue])

    const handleOperation = async (values: CreateContactFormType, contactId: string) => {
        if (!isValidOperation) return false;
        const { createUrl = '', updateUrl = '' } = extenalContactCreationConfig || {};

        let isSuccessful: boolean = false;
        setIsLoadingOperation(true);

        try {
            await fetcher({
                url: `${operationType == 'creation' ? createUrl : updateUrl}?id=${contactId}`,
                method: operationType == 'creation' ? 'POST' : 'PATCH',
                isExternalURL: true,
                withToken: true,
                body: values
            });

            toast({
                title: operationType === 'creation'
                    ? `Contacto creado exitosamente en el sistema ${extenalContactCreationConfig.integrationName || 'externo'}.`
                    : `Contacto actualizado exitosamente en el sistema ${extenalContactCreationConfig.integrationName || 'externo'}.`,
                description: operationType === 'creation'
                    ? 'El contacto ha sido registrado correctamente. Puedes continuar con las siguientes acciones en el sistema.'
                    : 'El contacto ha sido actualizado con éxito. Puedes verificar los cambios realizados en el sistema.',
                variant: 'success'
            });

            isSuccessful = true;
        } catch (error: any) {
            console.log('ERROR:', error);

            toast({
                title: operationType === 'creation'
                    ? `Error al crear el contacto en el sistema ${extenalContactCreationConfig?.integrationName || 'externo'}.`
                    : `Error al actualizar el contacto en el sistema ${extenalContactCreationConfig?.integrationName || 'externo'}.`,
                description: (error?.body?.error || error?.body?.message) || ((operationType === 'creation')
                    ? 'Ocurrió un problema durante el proceso de creación del contacto. Por favor, verifica la información e intenta nuevamente.'
                    : 'Hubo un problema al actualizar el contacto. Por favor, revisa los datos e intenta nuevamente.'),
                variant: 'destructive'
            });
        } finally {
            setIsLoadingOperation(false);
            return isSuccessful
        }
    };

    return {
        isEnabledExternalSystemOperation,
        isLoadingValidation,
        isLoadingOperation,
        isValidOperation,
        handleOperation,
        operationType,
        error
    }
}
