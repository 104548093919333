import React from 'react'
import ActivityInteractionSkeleton from './ActivityInteractionSkeleton'

export default function ActivityLoader() {
    return (
        <div className='flex flex-col space-y-2'>
            {
                Array.from({ length: 3 }).map((__, index) => {
                    return <ActivityInteractionSkeleton key={index} />
                })
            }
        </div>
    )
}
