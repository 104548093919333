import { fetcher } from "@/helpers/fetcher";

export const getDocumentUrl = ({ name, mimeType = '', disposition = '', expires }: {
    name: string,
    mimeType?: string,
    disposition?: string
    expires?: number
}) => {
    const searchParams = new URLSearchParams();

    if (disposition) searchParams.append('disposition', disposition)
    if (expires) searchParams.append('expires', expires.toString());

    return fetcher<{ url: string, key: string }>({
        url: `/aws/documents?${searchParams}`,
        method: 'POST',
        defaultContentType: true,
        body: {
            name,
            mimeType,
            via: 'web'
        }
    })
}