import { Button } from "@/components/ui/button"
import { CardDescription } from "@/components/ui/card"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { UserAvatar } from "@/components/ui/user-avatar"
import { formatFileSize } from "@/helpers/formatFileSize"
import { TransferAgent, TransferFile, TransferPopulated, TransferReceiptGroup } from "@/interfaces/crm/contact"
import { cn } from "@/lib/utils"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation"
import { ArrowDownToLine, ArrowRight, Building2, Inbox, Paperclip, ScrollText, User, Users } from "lucide-react"
import moment from "moment"
import { PropsWithChildren, ReactNode, useMemo } from "react"
import { InteractionStatus } from "./interaction-status"

moment.locale("es")

export interface IAgentData {
  _id: string
  name: string
  photo?: string
  lastname: string
  email: string
}

interface TransferBubble {
  interaction: TransferPopulated
}

const TransferBubble = ({ interaction }: TransferBubble) => {

  const { toUser, files = [], description, isInboxTransfer, recipientGroups = [], recipients = [] } = interaction?.content || {}
  const toAgentName = [toUser?.name, toUser?.lastname].filter(Boolean).join(' ');
  const date = new Date(interaction.createdAt);
  const createdBySystem = interaction.createdBySystem;
  const agent = interaction.agent;

  const GetDownElement = () => {
    if (!description && files.length == 0) return null;

    return (
      <div className="flex items-center gap-2 mt-2">
        {
          description && (
            <AttachedContent
              agent={agent}
              trigger={(
                <Button variant={'outline'} type="button" className="w-8 h-8 relative">
                  <ScrollText size={15} className="shrink-0" />
                </Button>
              )}
              content={(
                <div className="p-3">
                  <p className="text-[13px] text-gray-500">
                    <b>Nota:{' '}</b>
                    {description}
                  </p>
                </div>
              )}
            />
          )
        }
        {
          (files.length > 0) && (
            <AttachedContent
              agent={agent}
              trigger={(
                <Button variant={'outline'} type="button" className="w-8 h-8 relative">
                  <span className="w-4 h-4 absolute -top-1 -right-1 rounded-full shrink-0 text-white bg-primary flex items-center justify-center text-[10.5px]">
                    {files.length}
                  </span>
                  <Paperclip size={15} className="shrink-0" />
                </Button>
              )}
              content={(
                <ul className="flex flex-col gap-2 p-3 max-h-[300px] overflow-y-auto">
                  {files.map((file, inx) => <FileItem key={inx} file={file} />)}
                </ul>
              )}
            />
          )
        }
      </div>
    )
  }

  return (
    <DividerProspectAction
      date={date}
      description={`Realizado por ${createdBySystem ? 'el sistema' : `${agent?.name} ${agent?.lastname}`}`}
      downElement={GetDownElement()}
    >
      <TransferWithinAStationIcon sx={{ fontSize: "20px" }} />
      {
        !isInboxTransfer
          ? `Transferido a ${toAgentName}`
          : <>
            Transferido al <InboxTransferDetail
              recipientGroups={recipientGroups}
              recipients={recipients}
              transferedBy={agent}
            >
              <span className="inline text-primary cursor-pointer hover:underline">
                <Inbox size={18} className="inline mr-1.5" />
                inbox
              </span>
            </InboxTransferDetail>
          </>
      }
    </DividerProspectAction>
  )
}


interface AttachedContentProps {
  agent: IAgentData,
  content: ReactNode
  trigger: ReactNode
}

export function AttachedContent({ agent, content, trigger }: AttachedContentProps) {
  const agentName = [agent?.name, agent?.lastname].filter(Boolean).join(' ')

  return (
    <Popover>
      <PopoverTrigger asChild>
        {trigger}
      </PopoverTrigger>
      <PopoverContent className="w-80 p-0">
        <div className="grid">
          <header className="p-3 border-b">
            <div className="flex gap-2">
              <UserAvatar user={agent} className="w-[25px] mt-1 h-[24px]" />
              <div className="flex flex-col">
                <h5 className="text-[14px] font-medium text-gray-700">
                  {agentName}
                </h5>
                {
                  (agent.email) && (
                    <a
                      href={`mailto:${agent.email}`}
                      className="text-[12px] text-primary hover:underline"
                    >
                      {agent.email}
                    </a>
                  )
                }
              </div>
            </div>
          </header>
          {content}
        </div>
      </PopoverContent>
    </Popover>
  )
};


interface FileItemProps {
  file: TransferFile
}

export function FileItem({ file }: FileItemProps) {
  const { name, path, size } = file;

  const downloadFile = () => {
    const linkElement = document.createElement('a')
    linkElement.href = path
    linkElement.download = name
    linkElement.click()
  };

  return <li className="w-full flex items-center justify-between p-2 rounded-md border">
    <div className="flex items-center flex-1 gap-2">
      <div className="w-[50px] h-[50px] shrink-0 rounded-md overflow-hidden border relative">
        <img src={path} alt={name} className="w-full h-full object-cover" />
      </div>
      <div>
        <h5 className="text-[12px] mb-1 font-medium text-gray-600">
          {name}
        </h5>
        <p className="text-[12px] text-gray-500">
          {formatFileSize(size)}
        </p>
      </div>
    </div>
    <Button
      onClick={downloadFile}
      className="w-7 h-7"
      variant={'outline'}
      size={'icon'}
      type="button"
    >
      <ArrowDownToLine size={17} />
    </Button>
  </li>
}

export function DividerProspectAction({
  description,
  messageError,
  children,
  classNameInteractionStatus,
  retryFn,
  status,
  date,
  className = "",
  downElement
}: any) {
  const isError = status === LocalStatus.ERROR
  const lineStyle = isError ? 'bg-red-300' : 'bg-gray-200'

  const Line = useMemo(() => <span className={`flex-1 h-[1px] ${lineStyle}`}></span>, [isError])

  return (
    <div className="flex flex-col items-center justify-center flex-grow my-[8px]">
      <div className="flex-grow w-full md:px-4 px-0 flex items-center">
        {Line}
        <div className="flex flex-col gap-1 items-center px-[18px]">
          <div
            className={cn(`flex items-center gap-2 
              ${isError ? 'text-red-600' : 'text-slate-600'}`, className)}
          >
            <p className="md:text-[14px] text-sm [&>svg]:w-4  [&>svg]:h-4 [&>svg]:sm:w-[18px]  [&>svg]:sm:h-[18px] text-[12px] text-center flex items-center gap-2 font-medium ">
              {children}
            </p>
            {date && (
              <span className="text-[13px]">{moment(date).format("LT")}</span>
            )}
          </div>
        </div>
        {Line}
      </div>
      {description && (
        <CardDescription className="text-[12px]">{description}</CardDescription>
      )}
      {downElement}
      {
        !!retryFn && !!messageError && <InteractionStatus
          className={cn("mt-2", classNameInteractionStatus)}
          status={status as LocalStatus}
          handleRetry={retryFn}
          messageError={messageError}
        />
      }
    </div>
  )
}

type InboxTransferDetailProps = PropsWithChildren<{
  recipientGroups?: TransferReceiptGroup[],
  recipients?: TransferAgent[],
  transferedBy: IAgentData
}>

export function InboxTransferDetail({
  children,
  recipientGroups = [],
  recipients = [],
  transferedBy
}: InboxTransferDetailProps) {

  const renderUser = (user: TransferAgent) => (
    <span className="text-gray-700 font-medium text-[13px] flex items-center">
      <UserAvatar user={user} className="border w-[22px] h-[22px] text-[8px] mr-1" />
      {[user?.name, user?.lastname].filter(Boolean).join(' ')}
    </span>
  );

  const renderGroup = (group: TransferReceiptGroup) => (
    <span className="text-gray-700 font-medium text-[13px]">{group.displayName || group.businessName}</span>
  );

  return (
    <HoverCard openDelay={200}>
      <HoverCardTrigger asChild>{children}</HoverCardTrigger>
      <HoverCardContent className="w-96 p-3 text-start max-h-[400px] overflow-y-auto">
        <div className="text-start">
          <h4 className="text-[16px] font-medium text-gray-900">
            Detalles de la Transferencia
          </h4>
          <p className="text-[12px] font-normal text-gray-500">Resumen breve sobre la transferencia realizada al inbox.</p>
        </div>

        <div className="grid space-y-2.5 mb-5 mt-3">
          <span className="text-[12.5px] text-gray-600 flex items-center">
            <User size={17} className="mr-2" />
            Realizada por:
          </span>
          {renderUser(transferedBy as TransferAgent)}
        </div>
        <div>
          <span className="text-[12.5px] text-gray-600 flex items-center">
            {
              (recipients.length > 0)
                ? <Users size={17} className="mr-2" />
                : <Building2 size={17} className="mr-2" />
            }
            Transferido a:
          </span>
          <ul className="flex flex-col space-y-3 mt-2.5">
            {recipients.length > 0
              ? recipients.map((user, index) => <li key={index}>
                <span className="flex items-center">
                  <ArrowRight size={15} className="mr-1.5" />
                  {renderUser(user)}
                </span>
              </li>)
              : recipientGroups.map((group, index) => <li key={index}>
                <span className="flex items-center">
                  <ArrowRight size={14} className="mr-1.5" />
                  {renderGroup(group)}
                </span>
              </li>)
            }
          </ul>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}

export default TransferBubble
